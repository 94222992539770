export const freeplan = 'FREE';

export const planInverval = {
  monthly: 'monthly',
  yearly: 'yearly',
};

export const editModeEnum = {
  none: 0,
  button: 1,
  logo: 2,
};

export const authorizationStep = {
  none: '',
  pin: 'send_pin',
  otp: 'send_otp',
  phone_number: 'send_phone',
  birthday: 'send_birthday',
};

export const trxType = {
  none: 0,
  payg: 1,
  subscription: 2,
  topup: 3,
};

// This is just for testing some components/actions that depends on Boolean
export const isTrue = Boolean(1);

// Edge price for Dollar to naira is 420NGN
export const emailCreditInNaira = 0.42;

export const paygCreditInNaira = 0.5;

export const jobStatus = {
  pending: 0,
  uploading: 1,
  done: 2,
};

export const modalId = {
  turnOnCampaign: 'TURN_ON_CAMPAIGN',
};

export const intervals = {
  minutes: 60,
  hours: 60 * 60,
  days: 24 * 60 * 60,
  weeks: 7 * 24 * 60 * 60,
  months: 30 * 24 * 60 * 60,
  years: 365 * 24 * 60 * 60,
};

export const componentKeys = {
  uploadContactsToInstantEmail: 'uploadContactsToInstantEmail',
};

export const mailTypes = {
  webBuilder: 'web-builder',
  textEditor: 'text-editor',
  strippoEditor: 'speditor',
  simpleText: 'simple-text-editor',
};

export const instantEmailsActionTypes = {
  edit: 'edit',
  create: 'create',
};

export const mailStatusTypes = {
  active: 0,
  draft: 1,
  deleted: 2,
  scheduled: 3,
  completed: 4,
};

export const xProcessedStatusEnum = {
  draft: 'DRAFT',
  queued: 'QUEUED',
  processing: 'PROCESSING',
  scheduled: 'SCHEDULED',
  completed: 'COMPLETED',
  failed: 'FAILED',
};

export const editActions = {
  schedule: 'SCHEDULE',
  editTitle: 'EDIT_TITLE',
  editFromAndTo: 'EDIT_MAIL_PARAMS',
  editSubject: 'EDIT_SUBJECT',
  sendNow: 'SEND_NOW',
};

export const growthBookId = {
  strippo: 'strippo-feat',
  strippoIE: 'strippo-feat-ie',
  uiUpgrade: 'ui-upgrade',
  hideContactUploadIE: 'hide-contact-upload-ie',
  dashboardGraph: 'dashboard-graph',
  emailCreditWarningExperience: 'warn-msg-and-email-cr-exp',
  newsLetterEditor: 'hide-newsletter-editor',
  disableEmail: 'disable-send-email',
  landingPageDesign: 'landing_page_design',
  showOtherPaymentSub: 'hide-payment-subscription',
  paygPromoStartDate: 'payg-promo-start',
  paygPromoEndDate: 'payg-promo-end',
  hideGhanaCedis: 'hide-ghana-cedis'
};

export const returnPromoDates = (startDate, endDate) => {
  return {
    paygPromoStartDate: new Date(startDate),
    paygPromoEndDate: new Date(endDate),
  };
};

export const CAMPAIGN_POLLING_INTERVAL = 30000