import React from 'react';
import PropTypes from 'prop-types';
import { Select, Form, Button } from 'antd';
import { useAddNewBulkContactToGroupMutation } from 'redux/featureApi/contactApiSlice';
import { MdTypography } from 'components/global';
import { showToastMessage } from 'utils/Toast';
import CsvKeyValidator from 'components/emmaccen/modals/instantEmailUploadCsvModal/CsvKeyValidator';
import { useImportRecipientsCsvToCampaignMutation } from 'redux/featureApi/campaignApiSlice';
import style from 'styles/newContactPage.module.scss';
import InfoIcon from 'asset/Icons/InfoIcon';

const MdConfirmCsvUpload = ({
  saveContactForm,
  instantForm,
  setImVisible,
  csvResult,
  setCsvResult,
  uploadContactsTo,
  campaignId,
  setUploadStep,
  group,
  refetchContactDetails,
  refetchContactGroupsData,
}) => {
  const { Option } = Select;
  const [importRecipientsCsvToCampaign] = useImportRecipientsCsvToCampaignMutation();
  const [addNewBulkContactToGroup, { isLoading: creatingContact }] = useAddNewBulkContactToGroupMutation();

  const [MdCsvUploadForm] = Form.useForm();

  const csvFoundHeaders = Object.keys(Object.assign({}, ...csvResult));

  const onSubmitContactDetail = async ({ firstName, lastName, email, phone }) => {
    // const fullName = ;
    const convertResults = csvResult.map((data, i) => {
      return {
        firstName: String(data[firstName] ?? '').trim(),
        lastName: String(data[lastName] ?? '').trim(),
        email: String(data[email]).trim(),
      };
    }, {});

    const [firstContact] = convertResults;

    const firstRowValidator = new CsvKeyValidator(firstContact);
    if (!firstRowValidator.validateEmail()) {
      return showToastMessage({
        type: 'warning',
        title: 'Warning',
        description: 'Invalid Email format',
      });
    } else {
      const removeLastCsvIndex = convertResults;

      /**
    if uploading csv to a contact group,
    @return { addNewBulkContactToGroup({payload: { contacts: [...csvResult,  ], groupId: group },}) }
    // else if uploading to a Campaign as recipient
    // @return { importRecipientsCsvToCampaign({ payload: {contacts: [...csvResult], groupId: group }, campaignId}) }
     */

      if (uploadContactsTo === 'createNewContactGroup') {
        // setImLoading({ id: 'creatingContact' });
        await addNewBulkContactToGroup({
          payload: { contacts: [...removeLastCsvIndex], groupId: group },
        })
          .then(async (data) => {
            await refetchContactGroupsData()
              .unwrap()
              .then((res) => {
                const selectedGroups = saveContactForm.getFieldValue('groupList');
                const newGroup = res?.groups.find((grp) => grp?._id === group);
                const newSelectedGroups = [...selectedGroups, newGroup?._id];
                saveContactForm.setFieldsValue({ groupList: newSelectedGroups });
                instantForm.resetFields();
              });
            setUploadStep(2);
          })
          .finally(() => {
            setUploadStep(2);
          });
      } else if (uploadContactsTo === 'campaignRecipient') {
        importRecipientsCsvToCampaign({ payload: { contacts: removeLastCsvIndex, groupId: group }, campaignId })
          .unwrap()
          .then(async ({ data }) => {
            setUploadStep(2);
          });
      }
    }
  };

  return (
    <div className={style.addNewContactModal}>
      <div className="flex items-center">
        <MdTypography variant={'h5Medium'} className="text-center w-full">
          Tag your contacts
        </MdTypography>
      </div>
      <div className={`${style['instant-mail-alert']}  my-4`}>
        <InfoIcon /> &nbsp;&nbsp; Match the titles in your upload with our predefined tags
      </div>

      <div>
        <Form
          form={MdCsvUploadForm}
          onFinish={(data) => onSubmitContactDetail(data)}
          onFinishFailed={() => null}
          autoComplete="off"
          size="large"
          layout="vertical"
          requiredMark={false}
        >
          <Form.Item
            name="firstName"
            label="Match first name"
            data-test="match-first-name-email"
          >
            <Select
              showSearch
              mode="tags"
              placeholder="Select value for First Name"
              optionFilterProp="children"
              data-test="match-first-name-select-instant-email"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {csvFoundHeaders?.map((foundHead, i) => (
                <Option key={i} value={foundHead} data-test="match-first-name-test-instant-email">
                  {foundHead.trim()}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Match last name"
            data-test="match-last-name-email"
          >
            <Select
              showSearch
              mode="tags"
              placeholder="Select value for Last Name"
              optionFilterProp="children"
              data-test="match-last-name-select-instant-email"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {csvFoundHeaders?.map((foundHead, i) => (
                <Option key={i} value={foundHead} data-test="match-last-name-test-instant-email">
                  {foundHead.trim()}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="email"
            label="Match email address"
            data-test="match-email-email"
            
            rules={[{ required: true, message: 'A column in your file must be matched with Email!' }]}
          >
            <Select
              showSearch
              mode="tags"
              placeholder="Select value for Email"
              optionFilterProp="children"
              data-test="match-email-select-instant-email"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {csvFoundHeaders?.map((foundHead, i) => (
                <Option key={i} value={foundHead} data-test="match-email-test-instant-email">
                  {foundHead.trim()}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <div className="emmaccen pt-4">
            <div className="flex-space-btw actionBtns ">
              <Button
                size={'large'}
                type=""
                onClick={() => {
                  setUploadStep(0);
                }}
                className={style['import-btn']}
                style={{ color: '#1C1C1C' }}
              >
                Back
              </Button>
              <Button
                size={'large'}
                data-test="match-btn-test-csv-instant-email"
                type="primary"
                htmlType="submit"
                loading={creatingContact}
                className={`${style['instant-mail-btn']} mt-0 w-270`}
              >
                Create group
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

MdConfirmCsvUpload.propTypes = {
  uploadContactsTo: PropTypes.any,
  imVisible: PropTypes.any.isRequired,
  setImVisible: PropTypes.any.isRequired,
  csvResult: PropTypes.array.isRequired,
  currentGroup: PropTypes.any,
  setRefresh: PropTypes.any,
  campaignId: PropTypes.string,
  formInstance: PropTypes.any,
};

export default MdConfirmCsvUpload;
