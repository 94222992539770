import { Form, Select } from 'antd';

import { useState, useEffect, useRef, useCallback } from 'react';
import { MdGlobalButton, MdTypography } from 'components/global';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { growthBookId } from 'utils/constants';
import { containValidEmail } from 'utils/formRules';
import Cancels from 'asset/Icons/cancels';
import { CreateNewGroup } from './CreateNewGroup';
import { showToastMessage } from 'utils/Toast';

const Step1Subscribers = ({
  saveContactForm,
  setImVisible,
  imVisible,
  contactGroups,
  setContactGroups,
  refetchContactGroupsData,
  refetchSingleInstantEmail,
  fetchingContactGroups,
  groupsAdded,
  setGroupsAdded,
  subscribersCount,
  savedGroup,
  savedGroupsToInstantEmailsLoading,
  handleSaveInstantEmailContact,
  addedRecipients,
}) => {
  const { Option } = Select;
  const instantEmailCsvUpload = useFeatureIsOn(growthBookId.hideContactUploadIE);
  const [filteredGroups, setFilteredGroups] = useState([]);

  //single/multiple email states
  const [emailData, setEmailData] = useState('');
  const [addedMail, setAddedMail] = useState([]);
  const [disable, setDisable] = useState(false);
  const [emailLimit, setEmailLimit] = useState(0);
  const [validMail, setValidMail] = useState(false);
  const [recipRef, setRecipref] = useState([]);

  const inputRef = useRef();

  const [csvResult, setCsvResult] = useState(null);
  const [group, setGroup] = useState(null);

  const filterGroups = useCallback((groups) => {
    return groups?.filter((group) => !(group?._id === 'risky_contacts' || group?._id === 'all_contacts'));
  }, []);

  const [selectedValue, setSelectedValue] = useState([...savedGroup]);

  const filterAndSetGroups = useCallback(() => {
    selectedValue.length > 0 ? setGroupsAdded(true) : setGroupsAdded(false);
    if (contactGroups) {
      const filtered = filterGroups(contactGroups);
      setFilteredGroups(filtered.reverse());
    }
  }, [contactGroups, filterGroups, selectedValue, setGroupsAdded]);

  useEffect(() => {
    filterAndSetGroups();
  }, [filterAndSetGroups]);

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars

    if (group?.length > 0 || selectedValue?.length > 0) {
      const total = [...selectedValue, group];
      const uniqueItems = Array.from(new Map(total.map((item) => [item?._id, item])).values());
      setSelectedValue((pre) => [...pre, uniqueItems]);
    } else if (Array.isArray(selectedValue) && selectedValue?.length === 0 && group?.length === 1) {
      const total = [group];
      const uniqueItems = Array.from(new Map(total?.map((item) => [item?._id, item])).values());
      setSelectedValue(uniqueItems);
    }
    // eslint-disable-next-line
  }, [group]);

  const handleChange = (value) => {
    if (selectedValue > 0) {
      setGroupsAdded(true);
      setSelectedValue((pre) => [...pre, ...value]);
    } else {
      setGroupsAdded(false);
      setSelectedValue(value);
    }
  };

  const handleMailData = (e) => {
    let value = e.target.value;
    setEmailData(value);
  };

  //verify the email regex is correct...
  useEffect(() => {
    if (containValidEmail[0].pattern.test(emailData)) {
      setValidMail(true);
      if (validMail) {
        setDisable(true);
      }
    } else {
      setValidMail(false);
    }
    // eslint-disable-next-line
  }, [emailData]);

  //to get the input feild information, and store in an array
  useEffect(() => {
    if (emailData.includes(',')) {
      if (validMail) {
        const cutMail = emailData.replace(',', '');
        setAddedMail((prev) => [...prev, cutMail]);
        setEmailData('');
      }
    }

    if (groupsAdded === true || addedMail?.length > 0 || validMail) {
      setDisable(true);
    } else if (groupsAdded === false || addedMail?.length === 0) {
      setDisable(false);
    }

    // eslint-disable-next-line
  }, [emailData, groupsAdded, addedMail, emailLimit, validMail]);

  useEffect(() => {
    if (addedMail && addedMail?.length > 0) {
      setEmailLimit(addedMail?.length);
    }
  }, [addedMail]);

  const removeSingleMail = (indexToRemove) => {
    setAddedMail((prevItems) => prevItems.filter((item, index) => index !== indexToRemove));
    setEmailLimit(addedMail?.length);
  };

  // submiting the form
  const handleSubmitform = (groupList) => {
    // const savedGroupsId = savedGroup.map((obj) => obj._id);
    // let groupSelected = groups.filter((item) => !savedGroupsId.includes(item));
    if (emailData !== '') {
      setAddedMail((prev) => {
        const unSubmittedMail = [...prev, emailData];
        if (containValidEmail[0].pattern.test(emailData)) {
          handleSaveInstantEmailContact(groupList, unSubmittedMail);
        } else {
          // handleSaveInstantEmailContact(groups, addedMail);
          setRecipref([...prev, addedRecipients]);
          showToastMessage({
            type: 'error',
            title: 'Submit a Valid email',
            description: 'Add a valid email',
          });
        }
      });
      setEmailData('');
    } else {
      handleSaveInstantEmailContact(groupList, addedMail);
    }
  };

  useEffect(() => {
    setAddedMail(addedRecipients);
  }, [addedRecipients, recipRef]);

  return (
    <Form
      form={saveContactForm}
      name="basic"
      onFinish={({ groupList }) => handleSubmitform(groupList)}
      onFinishFailed={() => null}
      autoComplete="off"
      size="large"
      className="formOne"
      layout="vertical"
    >
      <CreateNewGroup
        imVisible={imVisible}
        setImVisible={setImVisible}
        contactGroups={filteredGroups}
        setCsvResult={setCsvResult}
        csvResult={csvResult}
        refetchSingleInstantEmail={refetchSingleInstantEmail}
        refetchContactGroupsData={refetchContactGroupsData}
        group={group}
        setGroup={setGroup}
        setContactGroups={setContactGroups}
        saveContactForm={saveContactForm}
      />
      <div className="instant-email-form-header">
        <Form.Item name="groupList" label="Select groups" className="form-group w-[400px]">
          <Select
            mode="multiple"
            data-test="select-test"
            style={{ width: '100%' }}
            placeholder={'Select Group'}
            onChange={handleChange}
            filterOption={(input, option) => {
              return option.label.toLowerCase().includes(input.trim().toLowerCase());
            }}
          >
            {filteredGroups?.map((data) => (
              <Option
                key={data?._id}
                data-test="option-test"
                field={data?.totalContacts}
                value={data?._id}
                label={data.title}
              >
                <div className="demo-option-label-item">{data.title}</div>
              </Option>
            ))}
          </Select>
        </Form.Item>
        <div>
          <p
            onClick={() => setImVisible({ id: 'createNewContactGroup' })}
            data-test="create-new-contact-group"
            className="instant-email-create-group"
          >
            Create new group
          </p>
        </div>
      </div>

      {instantEmailCsvUpload && (
        <div className="flex upload-contact">
          <MdTypography variant={'btnRegular'} className="or">
            OR
          </MdTypography>
          <MdTypography
            variant={'btnRegular'}
            onClick={() => {
              setImVisible({ id: 'confirmCsvUpload' });
            }}
            className="instant-email-form-link"
          >
            Upload Contact
          </MdTypography>
        </div>
      )}

      <div style={{ width: '300px' }}>
        <div className="flex items-center gap-1 my-1">
          <MdTypography variant={'btnRegular'}>Add email address</MdTypography>

          <span className="text-[#F2F2F2]">
            (Single or multiple) <span className={`${emailLimit >= 15 ? 'text-red-400' : null}`}>{emailLimit}/15</span>
          </span>
        </div>

        <div
          className={`${
            emailLimit >= 15 ? 'border-2 border-red-400  rounded-md' : 'border border-black'
          }  flex flex-wrap`}
        >
          {addedMail?.length !== 0 && (
            <div className="flex flex-wrap gap-1 p-1">
              {addedMail?.map((item, index) => (
                <p
                  key={index}
                  className={`${validMail ? null : 'bg-red-400'} p-1 flex items-center border rounded-sm`}
                  style={{ backgroundColor: '#F2F2F2' }}
                  data-test="single-mail-name"
                >
                  {item}
                  <span
                    onClick={() => removeSingleMail(index)}
                    className="mx-2 flex items-center mt-1"
                    data-test="close-test"
                  >
                    <Cancels height={20} width={20} />
                  </span>
                </p>
              ))}
            </div>
          )}

          <input
            onChange={handleMailData}
            value={emailData}
            placeholder="Enter email addresses separated by commas"
            id="sinlge"
            className="w-full "
            readOnly={emailLimit >= 15 ? true : false}
            ref={inputRef}
            data-test="Multiple-email-test"
          />
          <span
            className={`px-3 ${validMail || emailData === '' ? 'hidden text-[12px]' : 'block text-red-400 text-[8px]'}`}
          >
            Enter a valid mail
          </span>
        </div>

        <div
          className=" my-2 p-2 rounded-md"
          style={{ fontSize: '11px', backgroundColor: '#FFF9E6', border: '1px solid #FFECB2' }}
        >
          <p>
            Recipients that do not belong to any contact group will be automatically added to “All contacts” and will be
            visible in your saved contacts{' '}
          </p>
        </div>
      </div>

      <div className="flex justify-between items-end">
        <MdTypography variant={'btnRegular'} className="mt-4   or">
          Total Subscribers: <span data-test="subscribers-count"> {subscribersCount} </span>
        </MdTypography>

        <div className={`instant-email-form-button-header m-0 ${disable ? '' : 'button-opacity'}`}>
          <MdGlobalButton
            dataTestId="subscribers-save-btn"
            size={'large'}
            htmlType="submit"
            loading={savedGroupsToInstantEmailsLoading}
            disabled={!disable}
          >
            Done
          </MdGlobalButton>
        </div>
      </div>
    </Form>
  );
};

export default Step1Subscribers;
