import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/createNew.scss';
import { MdTypography } from '../components/global';
import MdGlobalButton from '../components/global/Button';
import banner_image from '../asset/images/banner_image.png';
import campaignImgModal from '../asset/images/campaignImgModal.png';
import { useAddInstantEmailIdMutation } from '../redux/featureApi/instantEmailApiSlice';
import { Form, Input, Modal } from 'antd';
import { useAddNewCampaignMutation, useGetUserCampaignsQuery } from '../redux/featureApi/campaignApiSlice';
import { showToastMessage } from '../utils/Toast';
import { CampaignLaptop } from '../asset/Icons/CampaignLaptop';
import { mustContainAtLeastOneChar } from 'utils/formRules';

const CreateNew = () => {
  const navigate = useNavigate();
  const [createNewCampaignForm] = Form.useForm();
  const [createNewInstantEmailForm] = Form.useForm();
  const [imVisible, setImVisible] = useState({ id: '' });
  //create campaign with rtk-query
  const [createCampaign, { isLoading: isCreatingCampaign }] = useAddNewCampaignMutation();
  const { data: campaignListData } = useGetUserCampaignsQuery();

  const [createInstantEmailService, { isLoading: isCreatingEmail }] = useAddInstantEmailIdMutation();
  const createInstantEmailFn = (instantEmailTitle) => {
    createInstantEmailService({ title: instantEmailTitle })
      .unwrap()
      .then((res) => {
        const instantEmailId = res?.data?.data?._id;
        navigate(`/instant-emails/drafts/${instantEmailId}`);
      })
      .catch((err) => {
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: 'Oops! An error occured',
        });
      });
  };

  const CreateNewCampaign = (name) => {
    const campaignTitle = campaignListData?.data?.campaigns?.map((item) => item.name);
    const campaignFormValue = createNewCampaignForm.getFieldValue().name.trim(' ');
    const campaignExist = campaignTitle?.some((campaign) => campaign?.toLowerCase() === campaignFormValue?.toLowerCase());

    if (campaignExist) {
      showToastMessage({
        type: 'success',
        title: 'Campaign Created',
        description: 'A campaign with this title already Exists ',
      });
    } else if (!campaignExist) {
      createCampaign({ name })
        .unwrap()
        .then((result) => {
          setImVisible({ id: '' });
          showToastMessage({
            type: 'success',
            title: 'Campaign Created',
            description: 'Campaign created successfully',
          });
          navigate(`/campaigns?new_campaign=${result.data.campaign._id}`);
          createNewCampaignForm.resetFields();
        })
        .catch((e) => {
          setImVisible({ id: '' });
          showToastMessage({
            type: 'error',
            title: 'Error',
            description: e.data?.error,
          });
        });
    }
  };

  const CreateNewCampaignModel = () => {
    return (
      <Modal
        open={imVisible.id === 'createNewCampaign'}
        onCancel={() => setImVisible({ id: '' })}
        footer={null}
        centered
        className="emmaccen create-campaign-modal"
      >
        <div>
          <MdTypography variant={'h5Medium'} className="text-center">
            Create Campaign
          </MdTypography>
          <div className="modalAlert">
            <div>
              <MdTypography variant={'btnLight'} className="modalText">
                A campaign is a series of emails that are delivered to a user in a specific order. These emails are
                carefully crafted and designed to engage your subscribers and guide them through a journey
              </MdTypography>
            </div>
            <div className="modalImg">
              <img src={campaignImgModal} alt="modal" className="object-contain" />
            </div>
          </div>
          <div>
            <Form
              form={createNewCampaignForm}
              name="basic"
              onFinish={({ name, number, seconds }) => CreateNewCampaign(name, number, seconds)}
              size="large"
              onFinishFailed={() => null}
            >
              <label className="mb-2 formLabel">Campaign title</label>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Please give your campaign a name',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <div className="centered mt-10">
                <MdGlobalButton id={'primary_lg'} type="primary" htmlType="submit" loading={isCreatingCampaign}>
                  <MdTypography variant={'h6Medium'} style={{ color: '#ffffff' }}>
                    Create Campaign
                  </MdTypography>
                </MdGlobalButton>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    );
  };

  const CreateInstantEmailModal = () => {
    return (
      <Modal
        open={imVisible.id === 'creatingInstantEmailModal'}
        onCancel={() => setImVisible({ id: '' })}
        footer={null}
        className="emmaccen create-instantEmail-modal"
        width={'412px'}
        centered
      >
        <div>
          <MdTypography variant={'h5Medium'} className="text-center title">
            Create Instant Email
          </MdTypography>
          <div className="info-container">
            <div>
              <MdTypography className="modalText">
                This will be created as a new email, and not under any specific campaign. Please enter the title of the
                email you would like to create and proceed.
              </MdTypography>
            </div>
            <img src={banner_image} alt="" />
          </div>
          <div>
            <Form
              form={createNewInstantEmailForm}
              name="basic"
              onFinish={({ title }) => {
                createInstantEmailFn(title.replace(/^\s+/, ''));
              }}
              size="large"
              onFinishFailed={() => null}
            >
              <div className="email-title">
                <label className="mb-2 formLabel">Email title</label>
                <Form.Item
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: 'Please give your instant email a title',
                    },
                    ...mustContainAtLeastOneChar,
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>

              <div className="centered">
                <MdGlobalButton
                  dataTestId="create-instant-email-submit"
                  id={'primary_lg'}
                  type="primary"
                  htmlType="submit"
                  loading={isCreatingEmail}
                >
                  <MdTypography variant={'h6Medium'} style={{ color: '#ffffff' }}>
                    Proceed
                  </MdTypography>
                </MdGlobalButton>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <>
      <CreateNewCampaignModel />
      <CreateInstantEmailModal />
      <div className="emmaccen">
        <div className="createEmailModal">
          <div className="hero">
            <div className="section">
              <div className="section-image">
                <div className="imgCon">{<CampaignLaptop />}</div>
              </div>
              <div className="email-builder">
                <MdGlobalButton className="text-btn">
                  <MdTypography variant={'btnLight'} className="text-paragraph">
                    Create a series of emails to<br></br> nuture leads
                  </MdTypography>
                </MdGlobalButton>
                <div className="select-button">
                  <MdGlobalButton
                    className="btn-campaign"
                    loading={false}
                    onClick={() => setImVisible({ id: 'createNewCampaign' })}
                  >
                    <MdTypography variant={'h6Medium'} className="campaign-text">
                      Create campaign
                    </MdTypography>
                  </MdGlobalButton>
                </div>
              </div>
            </div>
            <div className="section-sub ml-6">
              <div className="section-image">
                <div className="imgCon">
                  <img src={banner_image} alt="email" />
                </div>
              </div>
              <div className="email-builder">
                <MdGlobalButton className="text-button">
                  <MdTypography variant={'btnLight'} className="paragraph-text">
                    Create quick emails, send <br></br> instantly or schedule for later
                  </MdTypography>
                </MdGlobalButton>

                <div className="select-button">
                  <MdGlobalButton
                    className="email-text"
                    loading={isCreatingEmail}
                    onClick={() => setImVisible({ id: 'creatingInstantEmailModal' })}
                  >
                    <MdTypography variant={'h6Medium'} className="mail-text">
                      Create instant email
                    </MdTypography>
                  </MdGlobalButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateNew;
