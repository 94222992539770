import { Modal } from 'antd';
import React from 'react';
import styles from '../../styles/share.module.scss';
import {useSenderEmailVerificationMutation} from 'redux/featureApi/userApiSlice';
import { showToastMessage } from 'utils/Toast';
import { ReactComponent as Copiable } from '../../asset/Icons/Verify.svg';
import { useGetUserDetailsQuery } from 'redux/featureApi/userApiSlice';
import { ReactComponent as Verified } from '../../asset/Icons/Verified.svg';
import { ReactComponent as NotVerified } from '../../asset/Icons/NotVerified.svg';

const VerifyModal = ({ open,  setIsModalOpen,email ,handleClick}) => {
  const closeSuccessModal = () => setIsModalOpen(false);

  const [verified, setVerified] = React.useState(false);
  const [clicked, setClicked] = React.useState(true);
  
  const { data, isSuccess, refetch } = useGetUserDetailsQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const userdata = isSuccess && data?.data;
  const existingSenderEmail = userdata?.workSpaces[0]?.owner?.verifiedEmails;

  const [addEmail] = useSenderEmailVerificationMutation();
  
     const resendMail = async () => {
       try {
         await addEmail({
           email: email,
         })
           .unwrap()
           .then((res) => {
              showToastMessage({
                type: 'success',
                title: 'Success',
                description: 'Verification mail was sent successfully',
              });
           });
       } catch (error) {
         showToastMessage({
           type: 'error',
           title: 'Error',
           description: error,
         });
       }
    };

  const handleSubmit = () => {
    refetch();
    if (existingSenderEmail?.includes(email)) {
      // setIsModalOpen(false);
      setClicked(true);
      setVerified(true);
    } else {
      // setIsModalOpen(false);
      setClicked(true);
      setVerified(false);
    }
  }
  
 
  return (
    <div>
      <Modal
        title=""
        centered
        className={styles.modalContainer}
        open={open}
        onCancel={closeSuccessModal}
        footer={[
          <button key="submit" className={styles.modalButton} onClick={!clicked?handleSubmit:clicked&&!verified ?resendMail:setIsModalOpen(false)} type="primary">
           {!clicked &&<> Continue After Verification </>}
           {clicked && verified &&'Proceed'}
           {clicked && !verified && 'Resend Verification Email'}
          </button>
        ]}
      >
        <div className="text-center">
          <p className="text-green flex justify-content-center align-items-center" style={{ fontSize: '86px' }}>
          {!clicked &&  <Copiable />}
          {clicked && verified && <Verified/>}
          {clicked && !verified && <NotVerified/>}
          </p>
          
          {/* <p className={styles.verifyText}> */}
            {
              !clicked &&(
              <>
                  <p style={{ fontFamily: 'Paralucent-Medium' }} className="font18 mt-5 ">
                    Verify Your Email Address
                  </p>
                    <p className={styles.verifyText}> 
                  We've sent a verification link to [{email}]. Please check your inbox and click the link to confirm your
                  email address.<br/>
                  Please note: After successfully validating your email,the new sender email address will be automatically 
                  added to your account.To view the update, simply click back on the instant email or campaign.
                  </p>
                  <p className={styles.verifyText}>
                  Didn’t receive the email? Check your spam folder or{' '}
                  <span className="text-blue inline-block" onClick={handleClick}>
                    {' '}
                    resend the email
                  </span>
                  .
                </p>
              </>
              )}
            {
              clicked && verified &&
              <>
                <p style={{ fontFamily: 'Paralucent-Medium' }} className="font18 mt-5 ">
                  Email Verified
                </p>
                <p style={{ fontFamily: 'Paralucent-Light' }} className=" font16 mt-3">
                  Your email has been verified! You can now continue.
                </p>
              </>
            }
            {
              clicked && !verified &&(
                <>
                    <p style={{ fontFamily: 'Paralucent-Medium' }} className="font18 mt-5 ">
                    Email Not Verified Yet.
                  </p>
                  <p style={{ fontFamily: 'Paralucent-Light' }} className=" font16 mt-3">
                    We’re still waiting for you to verify your email. Please check your inbox and click the verification link
                  </p>
                </>
              )
            }
          {/* </p> */}
         
        </div>
      </Modal>
    </div>
  );
};

export default VerifyModal;
