import { apiAuthSlice } from './apiAuthSlice';

export const userApiSlice = apiAuthSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserDetails: builder.query({
      query: () => ({
        url: `/users/me`,
        method: 'GET',
      }),
      // keepUnusedDataFor: 0.0001,

      providesTags: ['UserDetails'],
    }),
    getAddVerifiedEmail: builder.query({
      query: (token) => ({
        url: `/users/add-verified-emails?token=${token}`,
        method: 'GET',
      }),
    }),
    senderEmailVerification: builder.mutation({
      query: ({ email }) => ({
        url: 'users/senderEmailVerification',
        method: 'POST',
        body: { email },
      }),
      invalidatesTags: ['SpecificInstantEmail'],
      transformResponse: (response) => response?.data,
    }),
  }),
  overrideExisting: true,
});

export const { useGetUserDetailsQuery, useGetAddVerifiedEmailQuery, useSenderEmailVerificationMutation } = userApiSlice;
