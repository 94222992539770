import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Collapse, Form, Input, Spin, Tooltip, Select } from 'antd';
import { MdTypography, MdGlobalButton } from '../../../components/global';
import React, { useEffect, useState } from 'react';
import { containAaZzHyphenSpace } from '../../../utils/formRules';
import { isUserOnFreePlan } from '../../../utils/generics';
import { useGetUserDetailsQuery } from 'redux/featureApi/userApiSlice';
import { MdPlus } from 'pages/ContactPage/Icons/MdPlus';
import AddSenderEmailModal from 'components/Modal/AddSenderEmailModal';
import VerifyModal from 'components/Modal/VerifyModal';

export const MdSettings = ({
  imLoading,
  isVariablesLoading,
  isAddingVariable,
  isDeletingVariable,

  campaignId,
  handleEmailConfiguration,
  handleVariables,
  handleDeleteVariable,
  campaignVariables,
  data,
}) => {
  const { Panel } = Collapse;

  const { Option } = Select;

    const { data:userData, isSuccess,refetch } = useGetUserDetailsQuery(
      {},
      {
        refetchOnMountOrArgChange: true,
      }
    );

    const user = isSuccess && userData?.data;
   

  //  const [users] = useState(JSON.parse(localStorage.getItem('userDetails')));
  const [userIsOnFreePlan] = useState(isUserOnFreePlan(user?.user));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [verifyModalOpen, setVerifyModalOpen] = useState(false);

 const showModal = () => {
     setIsModalOpen(true);
  };
  
  const handleClick = () => {
    setIsModalOpen(false);
    setVerifyModalOpen(true);
  };

  const handleVerify = () => {
    setVerifyModalOpen(false);
  };

  const [selectedMail, setSelectedMail] = useState();

  const handleChange = (value) => {
    setSelectedMail(value);
  };
  const [emailConfigForm] = Form.useForm();
  const [variablesForm] = Form.useForm();
  /* eslint-disable */
  useEffect(() => {
    emailConfigForm.setFieldsValue({
      ['emailFrom']: userIsOnFreePlan ? user?.emailSettings.emailFrom : data?.configurations?.emailSettings.from,
      ['emailReplyTo']: selectedMail || user?.emailSettings.emailReplyTo,
    });
  }, [selectedMail]);

  const text = `
    A dog is a type of domesticated animal.
    Known for its loyalty and faithfulness,
    it can be found as a welcome guest in many households across the world.
  `;

  const onChange = (key) => {};

  const CollapsePanelHeader = ({ title, subTitle, paid }) => (
    <div className="flex flex-col collapseHeader">
      <div className="flex items-center">
        <MdTypography variant={'h6Medium'}>{title}</MdTypography>
        {paid && (
          <MdTypography variant={'captionBold'} className="mx-4 paid_tag">
            PAID
          </MdTypography>
        )}
      </div>
      <MdTypography variant={'smallTextRegular'}>{subTitle}</MdTypography>
    </div>
  );
  return (
    <>
      <AddSenderEmailModal open={isModalOpen} setIsModalOpen={setIsModalOpen} handleClick={handleClick} />
      <VerifyModal open={verifyModalOpen} setIsModalOpen={setVerifyModalOpen} handleClick={handleVerify} />

      <div className="settingsTab">
        <Collapse onChange={onChange} accordion expandIconPosition="end">
          <Panel
          data-test="Custom-from-and-reply-to"
            header={
              <CollapsePanelHeader
                title={'Custom “from” and “reply-to”'}
                subTitle="Set a custom persona and reply-to email for your campaign"
                paid
              />
            }
            key="2"
          >
            <Form
              name="basic"
              // onFinish={(values) => {
              //   handleEmailConfiguration(values, campaignId);
              //   refetch();
              // }
              // }
              // onFinishFailed={() => null}
              autoComplete="off"
              size="large"
              className="formTwo"
              layout="vertical"
              form={emailConfigForm}
            >
              <div className="flex flex-column w-50 form_container">
                <div className="flex form_inputs">
                  <Form.Item
                    name="emailFrom"
                    className="setting-mobile"
                    label="From Name"
                    rules={[{ required: true, message: 'Please provide a name' }, ...containAaZzHyphenSpace]}
                  >
                    <Input placeholder="Default name" type="text" disabled={userIsOnFreePlan} />
                  </Form.Item>
                  <Form.Item
                    name="emailReplyTo"
                    className="setting-mobile pl-3"
                    label="Reply-to"
                    rules={[
                      { required: true, message: 'Please provide a reply-to' },
                      {
                        type: 'email',
                        message: 'Please provide a valid email address',
                      },
                    ]}
                  >
                    <Input placeholder="Default email" type="email" disabled={userIsOnFreePlan} />
                  </Form.Item>
                </div>
                {!userIsOnFreePlan && (
                  <div>
                    <div className="flex items-center mb-2 ">
                      <MdTypography className="gray-color">Sender Email Address</MdTypography>
                    </div>
                    <div className="sender-email-address-container flex align-items-center justify-center mb-10">
                      <Select
                        onChange={handleChange}
                        placeholder={'Select sender email address'}
                        data-test="toEmail-test"
                        value={selectedMail}
                      >
                        {user?.workSpaces[0]?.owner?.verifiedEmails?.map((data, index) => {
                          return (
                            <Option value={data} key={index}>
                              {data}
                            </Option>
                          );
                        })}
                      </Select>
                      <div className="ml-5">
                        <MdGlobalButton  data-test="addSenderEmailSettings" style={{ border: '1px solid #273DF2' }} className={`addGrpBtn  w-100`}>
                          <MdPlus className="pointer" />
                          <MdTypography
                            variant={'btnRegular'}
                            onClick={showModal}
                            style={{ color: '#273DF2' }}
                            className="addGroupText"
                          >
                            Add sender email address
                          </MdTypography>
                        </MdGlobalButton>
                      </div>
                    </div>
                  </div>
                )}
                <div className="form_submit ml-3 mb-6">
                  <Button
                    size={'large'}
                    onClick={(values) => {
                      handleEmailConfiguration(values, campaignId);
                      refetch();
                    }
                    }
                    type="primary"
                    htmlType="submit"
                    disabled={userIsOnFreePlan}
                    // onSubmit={(values) => handleEmailConfiguration(values, campaignId)}
                    loading={imLoading?.id === 'configuringEmail'}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Form>
          </Panel>
          <Panel
            header={
              <CollapsePanelHeader
                title="Merge Tags"
                subTitle="Create reusable tags for your emails and save time when drafting content"
              />
            }
            key="3"
          >
            <Form
              name="basic"
              onFinish={({ name, value }) => {
                handleVariables(name, value, campaignId);
                variablesForm.resetFields();
              }}
              onFinishFailed={() => null}
              form={variablesForm}
              autoComplete="off"
              layout="vertical"
              size="large"
            >
              <div className="flex  form_container">
                <div className="flex form_inputs">
                  <Form.Item
                    name="name"
                    className="pb-5"
                    label="Name"
                    rules={[{ required: true, message: 'Please provide a name' }]}
                  >
                    <Input placeholder="Input name" type="text" className="" />
                  </Form.Item>
                  <Form.Item
                    name="value"
                    className="pb-5 pl-3"
                    label="Value"
                    rules={[{ required: true, message: 'Please provide a value' }]}
                  >
                    <Input placeholder="Input value" type="text" />
                  </Form.Item>
                </div>
                <div className="form_submit pt-8 ml-3">
                  <Button size={'large'} type="primary" htmlType="submit" loading={isAddingVariable}>
                    Create tag
                  </Button>
                </div>
              </div>

              {!isVariablesLoading ? (
                <>
                  {campaignVariables.map((variable) => (
                    <div className="mt-5 variablesContainer borderShade1" key={variable.name}>
                      <div className="header">
                        <h2 className="font-bold">{variable.name}</h2>
                        <div className="pointer">
                          {isDeletingVariable && imLoading?.id === variable.name ? (
                            <Spin indicator={<LoadingOutlined style={{ fontSize: '16px', color: '#f45b5b' }} spin />} />
                          ) : (
                            <Tooltip trigger={['hover']} title={'Delete Variable'} color={'red'} placement="topLeft">
                              <DeleteOutlined
                                style={{ fontSize: '16px', color: '#f45b5b' }}
                                onClick={async () => {
                                  await handleDeleteVariable(variable.name);
                                }}
                              />
                            </Tooltip>
                          )}
                        </div>
                      </div>
                      <h2>{variable.value}</h2>
                    </div>
                  ))}
                </>
              ) : (
                <Spin indicator={<LoadingOutlined style={{ fontSize: 24, textAlign: 'center' }} spin />} />
              )}
            </Form>
          </Panel>
        </Collapse>
      </div>
    </>
  );
};

export default MdSettings;
