import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Divider, Dropdown, Form, Input, Modal, Select, Tabs } from 'antd';
import MdCampaignInformation from '../../components/emmaccen/campaignEmails/MdCampaignInformation';
import MdEmails from '../../components/emmaccen/campaignEmails/MdEmails';
import MdReceipients from '../../components/emmaccen/campaignEmails/MdRecipients';
import AddRecipientsToCampaign from '../../components/emmaccen/modals/AddRecipientsToCampaign';
import UploadCsvModal from '../../components/emmaccen/modals/uploadCsvModal';
import { MdGlobalButton, MdTypography } from '../../components/global';
import LoadingScreen from '../../components/LoadingScreen';
import ShareAccessDrop from '../../components/ShareAccessDrop';
// import { jobContext } from '../../contexts/job';
// import { SET_NEW_JOB } from '../../contexts/job/reducer/types';
import { modalsContext } from '../../contexts/modal';
import { OPEN_MODAL, SET_MODAL_PROPS } from '../../contexts/modal/reducer/types';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import {
  useAddCampaignVariableMutation,
  useAddRecipientToCampaignMutation,
  useDeleteCampaignUserMutation,
  useDeleteCampaignVariableMutation,
  useGetCampaignVariablesQuery,
  useGetSpecificCampaignDataQuery,
  useImportContactGroupsToCampaignMutation,
  useEditCampaignEmailConfigurationsMutation,
} from '../../redux/featureApi/campaignApiSlice';
import {
  useDeleteCampaignEmailMutation,
  useSendCampaignEmailToTrashMutation,
  useUpdateActiveEmailIntervalMutation,
} from '../../redux/featureApi/emailApiSlice';
import { useGetAllContactGroupsQuery } from 'redux/featureApi/contactApiSlice';
import { selectUserAccessLevel } from '../../redux/featureSlices/authSlice';
import { CAMPAIGN_POLLING_INTERVAL, modalId } from '../../utils/constants';
import { ReactComponent as ArrowDownIcon } from '../../asset/Icons/arrow-down.svg';
import downloadIcon from '../../asset/Icons/downloadIcon.png';
import '../../styles/campaigne.scss';
import { editInterval, getSingleCampaignInformation } from '../../utils/service';
import { showToastMessage } from '../../utils/Toast';
import MdFormsTab from '../../components/emmaccen/campaignEmails/MdFormsTab/index';
import MdSettings from '../../components/emmaccen/campaignEmails/MdSettings';
import { MdCreateCampaignEmailModal } from 'components/emmaccen/campaignEmails/MdCreateCampaignEmailModal';
import { MdDeleteEmailFromCampaign } from 'components/emmaccen/campaignEmails/MdDeleteEmailFromCampaign';
import { jobContext } from 'contexts/job';
import { SET_NEW_JOB } from 'contexts/job/reducer/types';
const MdCampaignEmails = () => {
  const location = useLocation();
  const { campaignId } = useParams();
  const { dispatch, state } = useContext(jobContext);
  const { dispatch: modalDispatch } = useContext(modalsContext);
  const [page, setPage] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [totalPages, setTotalPages] = useState(1);
  const [searchEmails, setSearchEmails] = useState('');

  const currentPage = page.pagination.current;
  const currentLimit = page.pagination.pageSize;

  const [jobId, setJobId] = useState();
  const [refreshTime, setRefreshTime] = useState(CAMPAIGN_POLLING_INTERVAL);
  const [searchInput, setSearchInput] = useState('');

  const { user } = JSON.parse(localStorage.getItem('userDetails'));
  const userName = user.username;
  const campaignTitle = localStorage.getItem('campaignTitle');

  const {
    data: campaignData,
    isLoading: isCampaignDataLoading,
    isFetching: isCampaignDataFetching,
    isError: isCampaignDataError,
    refetch: isCampaignRefetch,
  } = useGetSpecificCampaignDataQuery(
    { campaignId, currentPage, currentLimit },
    { refetchOnMountOrArgChange: true, pollingInterval: refreshTime, skipPollingIfUnfocused: true }
  );

  const { data: allContactGroups } = useGetAllContactGroupsQuery({ refetchOnMountOrArgChange: true });

  const filteredGroup = (contactGroups) =>
    contactGroups.filter((group) => !(group._id === 'risky_contacts' || group._id === 'all_contacts'));

  const [addRecipientToCampaign, { isLoading: isAddingRecipientToCampaign }] = useAddRecipientToCampaignMutation();
  const [importContactGroupsToCampaign, { isLoading: isImportingContactGroupsToCampaign }] =
    useImportContactGroupsToCampaignMutation();
  const [deleteCampaignUser, { isLoading: isDeletingCampaignUser }] = useDeleteCampaignUserMutation();
  const [editCampaignEmailConfigurations] = useEditCampaignEmailConfigurationsMutation();

  const [deleteEmail, { isLoading: isDeletingCampaignEmail }] = useDeleteCampaignEmailMutation();
  const [sendToTrash, { isLoading: isSendingEmailToTrash }] = useSendCampaignEmailToTrashMutation();

  const [editEmail, { isLoading: isUpdatingEmailInterval }] = useUpdateActiveEmailIntervalMutation();

  const {
    data: campaignVariablesData,
    isLoading: isCampaignVariablesLoading,
    isFetching: isCampaignVariablesFetching,
    isError: isCampaignVariablesError,
    error: campaignVariableError,
  } = useGetCampaignVariablesQuery(campaignId);
  const [addCampaignVariable, { isLoading: isAddingVariable }] = useAddCampaignVariableMutation();
  const [deleteCampaignVariable, { isLoading: isDeletingVariable }] = useDeleteCampaignVariableMutation();

  const [data, setData] = useState({ emails: [], name: '', users: [], mail: [], draft: [], deleted: [] });
  // we keep this as the original copy of our data
  const [campaigns, setCampaigns] = useState({
    emails: [],
    name: '',
    users: [],
  });

  const [isDeleting, setIsDeleting] = useState({ id: '' });
  const [imLoading, setImLoading] = useState({ id: '' });
  const [campaignVariables, setCampaignVariables] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [analyticsModal, setAnalyticsModal] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [csvResult, setCsvResult] = useState([]);
  const [recipients, setRecipients] = useState({
    raw: [],
    source: [],
  });
  const [modalVisible, setModalVisible] = useState({ id: '' });
  // eslint-disable-next-line
  const [contactGroups, setContactGroups] = useState([]);
  const [submitAddRecipients, setSubmitAddRecipients] = useState([]);
  const navigate = useNavigate();

  const { Option } = Select;
  const [addNewRecipientForm] = Form.useForm();

  const accessLevel = useSelector(selectUserAccessLevel);
  const isUploadContactFlag = useFeatureIsOn('show-contact-upload');

  const [breadCrumbs, setBreadCrumbs] = useState([
    {
      name: 'Overview',
      link: `/`,
      index: 0,
    },
  ]);

  const campaignInfoTab = `/campaign/${campaignId}/campaign-info`;
  const emailTab = `/campaign/${campaignId}/emails`;
  const recipientsTab = `/campaign/${campaignId}/recipients`;
  const formsTab = `/campaign/${campaignId}/forms`;
  const settingsTab = `/campaign/${campaignId}/settings`;

  const defaultToRequestedTab = () => {
    let defaultActiveKey;
    if (location.pathname === campaignInfoTab) {
      defaultActiveKey = 1;
    } else if (location.pathname === emailTab) {
      defaultActiveKey = 2;
    } else if (location.pathname === recipientsTab) {
      defaultActiveKey = 3;
    } else if (location.pathname === formsTab) {
      defaultActiveKey = 4;
    } else if (location.pathname === settingsTab) {
      defaultActiveKey = 5;
    } else {
      defaultActiveKey = 1;
    }
    return String(defaultActiveKey);
  };
  const [tabKey, setTabKey] = useState(defaultToRequestedTab());

  const [tabKeys] = useState({
    defaultActiveKey: defaultToRequestedTab(),
    campaignInfoT: '1',
    emailT: '2',
    recipientsT: '3',
    formsT: '4',
    settingsT: '5',
  });

  const handleDeleteEmail = (emailId) => {
    setModalVisible({ id: '' });
    isDeleting?.activeMail === 'deleted'
      ? deleteEmail({
          campaignId,
          emailId,
          //run: [() => setImLoading({ id: '' }), () => setIsDeleting({ id: '' })],
        }).then(() => {
          setRefresh(refresh + 1);
          showToastMessage({
            type: 'success',
            title: 'Mail Deleted',
            description: 'Mail deleted successfully',
          });
        })
      : sendToTrash({
          campaignId,
          emailId,
          // run: [() => setImLoading({ id: '' }), () => setIsDeleting({ id: '' })],
        }).then(() => {
          setRefresh(refresh + 1);
          showToastMessage({
            type: 'warning',
            title: 'Mail Trash',
            description: 'Mail moved to trash',
          });
        });
  };

  const loadVariables = () => {
    if (!isCampaignVariablesFetching && campaignVariablesData) {
      setCampaignVariables(campaignVariablesData?.data);
    } else if (!isCampaignVariablesFetching && isCampaignVariablesError) {
      showToastMessage({
        type: 'error',
        title: 'Error',
        description: campaignVariableError?.data?.message,
      });
    }
  };

  const searchCampaigns = (value) => {
    setImLoading('searchCampaign');
    value = value.toLowerCase().trim();
    tabKey === '2'
      ? (() => {
          setSearchEmails(value);
        })()
      : (() => {
          setRecipients((prev) => ({
            ...prev,
            source: recipients.raw.filter(
              ({ name, firstName, lastName, email }) =>
                name?.toLowerCase().includes(value) ||
                firstName?.toLowerCase().includes(value) ||
                lastName?.toLowerCase().includes(value) ||
                email.toLowerCase().includes(value)
            ),
          }));
          setImLoading({ id: '' });
        })();
  };

  const menuProps = {
    items: [
      {
        key: 'shareAccess',
        label: <ShareAccessDrop campaignId={campaignId} />,
      },
    ],
  };

  const campaigneActions = () => {
    return (
      tabKey !== '4' &&
      tabKey !== '5' && (
        <div className="flex item-center">
          {tabKey !== '1' && (
            <Input
              placeholder={tabKey === '2' ? 'Search emails...' : 'Search recipients...'}
              allowClear
              size="large"
              className="fade3"
              onChange={(e) => setSearchInput(e.target.value)}
              prefix={imLoading.id === 'searchCampaign' ? <LoadingOutlined /> : <SearchOutlined />}
              data-test="search-campaign"
            />
          )}
          <div className="ml-2">
            {tabKey === '1' ? (
              <div className="flex actionBtns">
                <Link
                  data-link="admin-edit"
                  to={{
                    pathname: `/join/${userName}/${campaignTitle}/admin-edit`,
                    search: `?edit=true&id=${campaignId}`,
                  }}
                >
                  <Button
                    size={'large'}
                    type="primary"
                    className="flex items-center text-sm btnPrimary"
                    data-test="edit-join-page-btn"
                    onClick={() => {}}
                  >
                    Edit Join Page
                  </Button>
                </Link>
                {accessLevel?.role !== 2 ? (
                  <div style={{ display: 'none' }}>
                    <Dropdown menu={menuProps} overlayClassName="share_access__dropdown" placement="bottomRight">
                      <Button
                        size={'large'}
                        style={{
                          background: 'rgba(242, 242, 242, 0.3)',
                          color: '#1c1c1c',
                          border: '1px solid rgba(242, 242, 242, 0.3)',
                          borderRadius: '4px',
                          marginLeft: '10px',
                        }}
                      >
                        Share access
                      </Button>
                    </Dropdown>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ) : tabKey === '2' ? (
              <div className="flex actionBtns">
                <Button
                  onClick={() => setModalVisible({ id: 'createNewEmailModal' })}
                  size={'large'}
                  type="primary"
                  className="flex items-center text-sm btnPrimary"
                  data-test="add-email"
                >
                  Add new email
                </Button>
              </div>
            ) : tabKey === '3' ? (
              <div className="flex actionBtns">
                <div className="mr-2">
                  <Button
                    data-test="add-recipient-button"
                    onClick={showModal}
                    size={'large'}
                    type="primary"
                    className="btnPrimary"
                  >
                    <MdTypography variant={'btnRegular'} style={{ color: '#ffffff' }}>
                      Add recipient +
                    </MdTypography>
                  </Button>
                </div>
                {isUploadContactFlag && (
                  <MdGlobalButton
                    id={'light_default'}
                    dataTestId="campaign-import-recipients"
                    className="border"
                    onClick={() => setModalVisible({ id: 'confirmCsvUpload' })}
                  >
                    <MdTypography variant={'btnRegular'} style={{ color: '#8e8e8e' }}>
                      Import
                    </MdTypography>
                    <img src={downloadIcon} alt="..." className="img-fluid" />
                  </MdGlobalButton>
                )}
              </div>
            ) : (
              <div className="flex actionBtns">
                <div className="mr-2">
                  <Button
                    data-test="add-recipient-button"
                    onClick={showModal}
                    size={'large'}
                    type="primary"
                    className="btnPrimary"
                  >
                    Add Recipient +
                  </Button>
                </div>
                {isUploadContactFlag && (
                  <Button
                    size={'large'}
                    className="border btnGhost"
                    onClick={() => setModalVisible({ id: 'confirmCsvUpload' })}
                  >
                    Import
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      )
    );
  };

  useEffect(() => {
    if (!isCampaignDataFetching && campaignData) {
      setPage({
        ...page,
        pagination: {
          ...page.pagination,
          total: campaignData?.data?.users?.length,
          // total: campaignData?.data?.data?.totalPages*10
          // 200 is mock data, you should read it from server
          // total: data.totalCount,
        },
      });
      setData({ ...data, ...campaignData.data });
      setCampaigns(campaignData.data);
      setBreadCrumbs([
        ...breadCrumbs,
        {
          name: campaignData.name,
          link: `#`,
          index: 1,
        },
      ]);
      setTotalPages(campaignData?.data?.totalPages * currentLimit);
      setRecipients({ raw: campaignData?.data?.users, source: campaignData?.data?.users });
    } else if (!isCampaignDataFetching && isCampaignDataError) {
      showToastMessage({
        type: 'error',
        title: 'Error',
        description: 'Unable to fetch data',
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignData, isCampaignDataFetching, isCampaignDataError]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const deleteUserFromCampaign = (campaignId, userId) => {
    setImLoading({ id: userId });
    deleteCampaignUser({ campaignId, userId })
      .then(({ data }) => {
        if (data.success) {
          setRecipients({ raw: data.data.users, source: data.data.users });
          showToastMessage({
            type: 'success',
            title: 'Receipient',
            description: 'Recipient deleted successfully',
          });
        }
      })
      .catch((error) => {
        const errorObject = error.response.data;
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: errorObject.message || 'Oops! An error occured',
        });
      })
      .finally(() => setImLoading({ id: '' }));
  };
  const onSubmit = (firstName, lastName, email, groupOfContacts, campaignId) => {
    const showWarningToast = (msg) =>
      showToastMessage({
        type: 'warning',
        title: 'Warning',
        description: msg,
      });
    if (!submitAddRecipients[3].value && groupOfContacts.length === 0) {
      showWarningToast('Sorry, you can`t submit without an email address');
      setImLoading({ id: '' });
    } else {
      setImLoading({ id: 'addingRecipient' });
      // SET PROPS ON MODAL
      modalDispatch({
        type: SET_MODAL_PROPS,
        payload: {
          id: modalId.turnOnCampaign, // Modal unique ID
          data: { campaignId },
        },
      });

      if (groupOfContacts?.length) {
        const formattedPayload = groupOfContacts.map((p) => String(p).toLowerCase().replace(' ', '_'));
        importContactGroupsToCampaign({ groups: formattedPayload, campaignId })
          .unwrap()
          .then(async ({ data }) => {
            handleCancel();
            showToastMessage({
              type: 'success',
              title: 'Recipient',
              description: data?.message || 'Recipients added successfully',
            });
            setJobId(data._id);
            dispatch({
              type: SET_NEW_JOB,
              payload: {
                ...data,
                ID: data._id,
                // Add to job object so that we can persist modal data with LS even if user refreshes the app
                campaign: {
                  id: modalId.turnOnCampaign, // Modal unique ID
                  props: { campaignId },
                },
              },
            });
          })
          .catch((e) => {
            setImLoading({ id: '' });
            showToastMessage({
              type: 'error',
              title: 'Error',
              description: e.response?.data?.error || e.message || 'Unable to process your request',
            });
          })
          .finally(() => {
            addNewRecipientForm.resetFields();
          });
      } else {
        const payload = { firstName, lastName, email, campaignId };
        if (!firstName || firstName.trim() === '') {
          delete payload.firstName;
        }
        if (!lastName || lastName.trim() === '') {
          delete payload.lastName;
        }

        addRecipientToCampaign(payload)
          .unwrap()
          .then(({ data }) => {
            handleCancel();
            showToastMessage({
              type: 'success',
              title: 'Recipient',
              description: data.message,
            });

            (async function () {
              try {
                const { data } = await getSingleCampaignInformation(campaignId);
                // If campaignStatus is false
                if (data.data.status === false) {
                  modalDispatch({ type: OPEN_MODAL, payload: modalId.turnOnCampaign });
                }
              } catch (error) {
                console.log({ error });
              }
            })();
          })
          .catch((e) => {
            setImLoading({ id: '' });
            showToastMessage({
              type: 'error',
              title: 'Error',
              description: e.response?.data?.error || e?.data?.error || e.message || 'Unable to process your request',
            });
          })
          .finally(() => {
            addNewRecipientForm.resetFields();
          });
      }
    }
  };

  function handleInterval(number, seconds, campaignId, intervalForm) {
    setImLoading({ id: 'setIntervalBtn' });
    editInterval(campaignId, { interval: `${number * seconds}` })
      .then(() => {
        setImLoading('');
        showToastMessage({
          type: 'success',
          title: 'Request Successful',
          description: 'Campaign interval updated successfully',
        });
      })
      .catch((e) => {
        setImLoading('');
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: e.response?.data?.fieldsError?.interval?.msg || e.response?.data?.message,
        });
      });
  }

  function handleEmailInterval(emailInterval, campaignId, id) {
    editEmail({ campaignId, pb: { emailInterval, id } })
      .then(() => {
        showToastMessage({
          type: 'success',
          title: 'Request Successful',
          description: 'Email interval updated successfully',
        });
      })
      .catch((e) => {
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: e.response?.data?.fieldsError?.interval?.msg || e.response?.data?.message,
        });
      });
  }

  const handleVariables = (name, value, campaignId) => {
    addCampaignVariable({ name, value, campaignId })
      .unwrap()
      .then((res) => {
        showToastMessage({
          type: 'success',
          title: 'Variable',
          description: 'Variable created successfully',
        });
      })
      .catch((e) => {
        setImLoading('');
        showToastMessage({
          type: 'error',
          title: "Couldn't Create Variable",
          description: e.response?.data?.message || e.response?.data?.error || e?.data?.error,
        });
      });
  };

  const handleDeleteVariable = (name) => {
    setImLoading({ id: name });
    deleteCampaignVariable({ name, campaignId })
      .then(() => {
        showToastMessage({
          type: 'success',
          title: 'Request Successful',
          description: 'Variable deleted successfully',
        });
      })
      .catch((e) => {
        showToastMessage({
          type: 'error',
          title: "Couldn't Delete Variable",
          description: e.response?.data?.message || e.response?.data?.error,
        });
      })
      .finally(() => setImLoading({ id: '' }));
  };

  const handleEmailConfiguration = (payload, campaignId) => {
    setImLoading({ id: 'configuringEmail' });
    editCampaignEmailConfigurations({ payload, campaignId })
      .unwrap()
      .then(() => {
        showToastMessage({
          type: 'success',
          title: 'Campaign Configuration',
          description: 'Campaign configurations has been updated successfully',
        });
      })
      .catch((e) => {
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: e.response?.data?.error || e.message || 'Unable to process your request',
        });
      })
      .finally(() => {
        setImLoading({ id: '' });
      });
  };

  const onFieldsChange = (a, b) => {
    setSubmitAddRecipients(b);
  };

  const tabItems = [
    {
      label: 'Campaign Information',
      key: '1',
      disabled: isCampaignDataLoading,
      children: isCampaignDataLoading ? (
        <LoadingScreen />
      ) : (
        <MdCampaignInformation data={data} campaignId={campaignId} campaigns={campaigns} />
      ),
    },
    {
      label: 'Emails',
      key: '2',
      disabled: isCampaignDataLoading,
      children: isCampaignDataLoading ? (
        <LoadingScreen />
      ) : (
        <MdEmails
          data={data}
          searchEmails={searchEmails}
          setData={setData}
          imLoading={imLoading}
          setRefreshTime={setRefreshTime}
          refreshTime={refreshTime}
          analyticsModal={analyticsModal}
          setAnalyticsModal={setAnalyticsModal}
          setModalVisible={setModalVisible}
          setIsDeleting={setIsDeleting}
          campaignId={campaignId}
          isDeleting={isDeleting}
          setRefresh={setRefresh}
          handleDeleteEmail={handleDeleteEmail}
          isDeletingCampaignEmail={isDeletingCampaignEmail}
          isSendingEmailToTrash={isSendingEmailToTrash}
          handleEmailInterval={handleEmailInterval}
          editEmail={editEmail}
          isUpdatingEmailInterval={isUpdatingEmailInterval}
        />
      ),
    },
    {
      label: 'Recipients',
      key: '3',
      disabled: isCampaignDataLoading,
      children: (
        <MdReceipients
          recipients={recipients}
          imLoading={imLoading}
          setImLoading={setImLoading}
          isDeletingCampaignUser={isDeletingCampaignUser}
          campaignId={campaignId}
          campaigns={campaigns}
          currentPage={currentPage}
          isCampaignDataFetching={isCampaignDataLoading || isCampaignDataFetching}
          deleteUserFromCampaign={deleteUserFromCampaign}
          totalPages={totalPages}
          page={page}
          setPage={setPage}
        />
      ),
    },
    {
      label: 'Forms',
      key: '4',
      disabled: isCampaignDataLoading,
      children: isCampaignDataLoading ? <LoadingScreen /> : <MdFormsTab campaignId={campaignId} data={data} />,
    },
    {
      label: 'Settings',
      key: '5',
      disabled: isCampaignDataLoading,
      children: isCampaignDataLoading ? (
        <LoadingScreen />
      ) : (
        <MdSettings
          imLoading={imLoading}
          isVariablesLoading={isCampaignVariablesLoading}
          isAddingVariable={isAddingVariable}
          isDeletingVariable={isDeletingVariable}
          handleInterval={handleInterval}
          campaignId={campaignId}
          handleEmailConfiguration={handleEmailConfiguration}
          handleVariables={handleVariables}
          handleDeleteVariable={handleDeleteVariable}
          campaignVariables={campaignVariables}
          data={data}
        />
      ),
    },
  ];

  useEffect(() => {
    // Function to handle click events
    const handleClick = (event) => {
      const campaignPreviewElement = document.querySelector('.campaignSchedulerInputs');
      const showCampaignAnalyticsButton = document.querySelector('.showCampaignAnalyticsButton');
      const analyticsModalElement = document.querySelector('.mailAnalyticsModal');

      if (
        showCampaignAnalyticsButton?.contains(event.target) ||
        analyticsModalElement?.contains(event.target) ||
        analyticsModal === true
      ) {
        return setRefreshTime(null);
      }

      if (campaignPreviewElement?.contains(event.target)) {
        // Click happened inside the campaignPreview
        setRefreshTime(null);
      } else {
        // Click happened outside the campaignPreview
        setRefreshTime(CAMPAIGN_POLLING_INTERVAL);
      }
    };

    // Add click event listener
    document.addEventListener('click', handleClick);

    // Cleanup listener on component unmount
    return () => {
      document.removeEventListener('click', handleClick);
    };

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const foundJob = state.jobs.find((x) => (x.ID = jobId));
    if (foundJob && foundJob.total === foundJob.processed) {
      isCampaignRefetch();
    }
    // eslint-disable-next-line
  }, [state]);

  useEffect(loadVariables, [
    isCampaignVariablesFetching,
    campaignVariableError,
    campaignVariablesData,
    isCampaignVariablesError,
  ]);

  useEffect(() => {
    if (allContactGroups) {
      setContactGroups(allContactGroups?.groups);
    }
  }, [allContactGroups]);

  useEffect(() => {
    const handleTabChange = () => {
      const currentPath = location.pathname;

      const tabKeyMap = {
        [campaignInfoTab]: tabKeys.campaignInfoT,
        [emailTab]: tabKeys.emailT,
        [recipientsTab]: tabKeys.recipientsT,
        [formsTab]: tabKeys.formsT,
        [settingsTab]: tabKeys.settingsT,
      };

      const activeKey = tabKeyMap[currentPath] || campaignInfoTab;

      setTabKey(String(activeKey));
      // Remove the navigate call
    };

    handleTabChange();
  }, [
    location.pathname,
    campaignInfoTab,
    emailTab,
    recipientsTab,
    formsTab,
    settingsTab,
    tabKeys.campaignInfoT,
    tabKeys.emailT,
    tabKeys.recipientsT,
    tabKeys.formsT,
    tabKeys.settingsT,
    setTabKey,
  ]);

  useEffect(() => {
    searchCampaigns(searchInput);
    if (searchInput.length > 0) {
      setRefreshTime(null);
    } else {
      setRefreshTime(CAMPAIGN_POLLING_INTERVAL);
    }
    // eslint-disable-next-line
  }, [searchInput]);

  return (
    <div className="emmaccen">
      <div className="campaignDetails">
        <MdCreateCampaignEmailModal
          handleModal={setModalVisible}
          isModalVisible={modalVisible.id === 'createNewEmailModal'}
          id={campaignId}
        />
        <MdDeleteEmailFromCampaign
          handleModal={setModalVisible}
          isModalVisible={modalVisible.id === 'deletingEmailModal'}
          isDeleting={isDeleting}
          handleDeleteEmail={handleDeleteEmail}
        />

        <Modal
          data-test="addNewModal"
          className="emmaccen"
          open={isModalVisible}
          footer={null}
          maskClosable={false}
          closable={false}
        >
          <div className="addNewRecipient">
            <h2 className="text-center title">Add New</h2>
            <Form
              name="basic"
              form={addNewRecipientForm}
              onFinish={({ firstName, lastName, email, groupOfContacts }) => {
                if (groupOfContacts) {
                  const emptyGroupDetected = groupOfContacts.some(
                    (id) => contactGroups.find((cg) => cg._id === id)?.totalContacts === 0
                  );
                  if (emptyGroupDetected) {
                    return showToastMessage({
                      type: 'error',
                      title: 'Empty Group(s) Detected',
                      description: 'Empty group(s) cannot be added to campaign',
                    });
                  }
                }
                onSubmit(firstName, lastName, email, groupOfContacts, campaignId);
              }}
              onFinishFailed={() => null}
              onFieldsChange={onFieldsChange}
              autoComplete="off"
              size="large"
              layout="vertical"
            >
              <Form.Item name="groupOfContacts" label="Add from Group">
                <Select
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder="Select Group"
                  optionLabelProp="label"
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  data-test="addGroupsToCampaign"
                >
                  {filteredGroup(contactGroups)?.map((data) => {
                    return (
                      <Option key={data._id ?? data.title} value={data._id} label={data.title}>
                        <div className="demo-option-label-item">{data.title}</div>
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Divider children={'OR'} />

              <div className="newRecipientsDir">
                <span>New recipient</span>
                <ArrowDownIcon />
              </div>
              <Form.Item
                name="firstName"
                label="First Name"
                // rules={
                //   contactFormGroups && contactFormGroups?.length > 0
                //     ? []
                //     : [
                //         {
                //           message: 'Please, provide a name!',
                //           required: true,
                //         },
                //         ...containAzDchar,
                //         ...mustContainAtLeastOneChar,
                //       ]
                // }
              >
                <Input data-test="add-recipient-firstName" placeholder="John Doe" />
              </Form.Item>
              <Form.Item
                name="lastName"
                label="Last Name"
                // rules={
                //   contactFormGroups && contactFormGroups?.length > 0
                //     ? []
                //     : [
                //         {
                //           message: 'Please, provide a name!',
                //           required: true,
                //         },
                //         ...containAzDchar,
                //         ...mustContainAtLeastOneChar,
                //       ]
                // }
              >
                <Input data-test="add-recipient-lastName" placeholder="John Doe" />
              </Form.Item>

              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    message: 'Please, provide an email!',
                    type: 'email',
                  },
                ]}
              >
                <Input placeholder="johndoe@person.com" data-test="add-recipient-email" />
              </Form.Item>
              <div className="emmaccen">
                <div className="flex-space-btw actionBtns">
                  <Button size={'large'} type="" onClick={handleCancel}>
                    Back
                  </Button>
                  <Button
                    size={'large'}
                    type="primary"
                    htmlType="submit"
                    loading={isAddingRecipientToCampaign || isImportingContactGroupsToCampaign}
                    data-test="add-recipient-submit"
                  >
                    Done
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </Modal>

        <UploadCsvModal
          csvResult={csvResult}
          setCsvResult={setCsvResult}
          uploadContactsTo="campaignRecipient"
          imVisible={modalVisible}
          setImVisible={setModalVisible}
          setRefresh={setRefresh}
          campaignId={campaignId}
          contactGroups={filteredGroup(contactGroups)}
        />

        <AddRecipientsToCampaign
          imVisible={modalVisible.id === 'addNewRecipientToCampaign'}
          campaignId={campaignId}
          setImVisible={setModalVisible}
          setRefresh={setRefresh}
          imLoading={imLoading}
          setImLoading={setImLoading}
        />
        <div className="layoutWrapper" data-test="tab-wrapper">
          <Tabs
            onTabClick={(key) => {
              if (key === tabKeys.campaignInfoT) {
                navigate(campaignInfoTab);
              } else if (key === tabKeys.emailT) {
                navigate(emailTab);
              } else if (key === tabKeys.recipientsT) {
                navigate(recipientsTab);
              } else if (key === tabKeys.formsT) {
                navigate(formsTab);
              } else if (key === tabKeys.settingsT) {
                navigate(settingsTab);
              }
              setTabKey(key);
            }}
            // defaultActiveKey={tabKeys?.defaultActiveKey}
            tabBarExtraContent={campaigneActions(campaignId)}
            items={tabItems}
            activeKey={tabKey}
          />
          {/* <Divider /> */}
        </div>
      </div>
    </div>
  );
};
export default MdCampaignEmails;
