import { useState,useEffect } from 'react';
import { Form, Input, Select } from 'antd';
import { MdGlobalButton, MdTypography } from 'components/global';
import EmailDisplayPrototype from '../EmailDisplayPrototype';
import { editActions } from '../../../../utils/constants';
import { min3char } from '../../../../utils/formRules';
import { isUserOnFreePlan } from '../../../../utils/generics';
import { MdPlus } from 'pages/ContactPage/Icons/MdPlus';
import { useGetUserDetailsQuery } from 'redux/featureApi/userApiSlice';
import AddSenderEmailModal from 'components/Modal/AddSenderEmailModal';

const Step2FromandReplyTo = ({
  fromAndReplyToForm,
  fromName,
  toEmail,
  setToEmail,
  emailData,
  setFromName,
  emailSubject,
  handleSaveFromAndReplyTo,
  isEditingMail,
  editAction,
  isFromAndReplyToValidated,
  setIsFromAndReplyToValidated,
}) => {
  const { Option } = Select;
  
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMail, setSelectedMail] = useState(null);

const { data, isSuccess ,refetch} = useGetUserDetailsQuery(
  {},
  {
    refetchOnMountOrArgChange: true,
  }
);

  const userdata = isSuccess && data?.data;
  
  const [userIsOnFreePlan] = useState(isUserOnFreePlan(userdata.user));

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleClick = () => {
    setIsModalOpen(false);
  };

  const handleChange = (value) => {
    setSelectedMail(value);
     setIsFromAndReplyToValidated(true);
  };

 
  useEffect(() => {
    if (selectedMail !== null) {
      fromAndReplyToForm.setFieldsValue({
        to: selectedMail,
      });
    } else {
      fromAndReplyToForm.setFieldsValue({
        to: emailData?.to,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMail, userdata, fromAndReplyToForm]);

  

  return (
    <>
      <AddSenderEmailModal open={isModalOpen} setIsModalOpen={setIsModalOpen} handleClick={handleClick} />
      <Form
        form={fromAndReplyToForm}
        name="basic"
        // onFinish={(data) => {
        //   handleSaveFromAndReplyTo(data);
        //   refetch();
        // }}
        // onFinishFailed={() => null}
        autoComplete="off"
        size="large"
        className="instant-email-form-container"
        layout="vertical"
        onFieldsChange={(fields) => {
          if (fields[0].errors.length > 0) {
            setIsFromAndReplyToValidated(false);
          } else {
            setIsFromAndReplyToValidated(true);
          }
        }}
      >
        <div className="instant-email-form-wrapper">
          <Form.Item
            name="from"
            className="setting-mobile"
            label="From Name"
            rules={[
              {
                required: true,
                message: 'Please enter your name',
              },
              ...min3char,
              // ...containAzDchar,
            ]}
          >
            <Input
              placeholder="Default name"
              type="text"
              data-test="fromName-test"
              onChange={(e) => {
                if (e.target.value.length < 1) {
                  setFromName('Default Name');
                  return;
                }
                setFromName(e.target.value);
              }}
            />
          </Form.Item>
          {userIsOnFreePlan ? (
            <Form.Item
              name="to"
              className="setting-mobile "
              label="Reply-to"
              rules={[
                {
                  required: true,
                  message: 'Please enter a valid email',
                  type: 'email',
                },
              ]}
            >
              <Input placeholder="Default Email" type="email" data-test="toEmail-test" />
            </Form.Item>
          ) : (
            <Form.Item
              name="to"
              className="setting-mobile "
              label="Reply-to"
              rules={[
                {
                  required: true,
                  message: 'Please enter a valid email',
                  type: 'email',
                },
              ]}
            >
              <Input
                type="email"
                placeholder="Default email"
                value={selectedMail ? selectedMail : toEmail}
                data-test="toEmail-test"
              />
            </Form.Item>
          )}
          <Form.Item
            name="sender-email"
            className="setting-mobile "
            label=""
            disabled={userIsOnFreePlan}
            rules={[
              {
                required: false,
                message: 'Please enter a valid email',
                type: 'email',
              },
            ]}
          >
            {userIsOnFreePlan ? (
              <>
                <div className="flex items-center mb-2 ">
                  <MdTypography className="gray-color">Sender Email Address</MdTypography>
                  <MdTypography variant={'captionBold'} className="mx-4 paid_tag">
                    PAID
                  </MdTypography>
                </div>
                <Input placeholder="Sender Email" type="email" data-test="toEmail-test" disabled={userIsOnFreePlan} />
              </>
            ) : (
              <div>
                <div className="flex items-center mb-2 ">
                  <MdTypography className="gray-color">Sender Email Address</MdTypography>
                </div>
                <div className="sender-email-address-container">
                  {userdata?.workSpaces[0]?.owner?.verifiedEmails?.length > 0 ? (
                    <Select
                      placeholder={'Select sender email address'}
                      onChange={handleChange}
                      data-test="senderEmail-test"
                      value={selectedMail}
                    >
                      {userdata?.workSpaces[0]?.owner?.verifiedEmails?.map((data, index) => {
                        return (
                          <Option value={data} key={index}>
                            {data}
                          </Option>
                        );
                      })}
                    </Select>
                  ) : (
                    <Select placeholder={'Select sender email address'} data-test="toEmail-test">
                      <Option>nil</Option>
                    </Select>
                  )}
                  <div className="mt-5">
                    <MdGlobalButton data-test="addSenderEmailTest" style={{ border: '1px solid #273DF2' }} className={`addGrpBtn  w-100`}>
                      <MdPlus className="pointer" />
                      <MdTypography
                        variant={'btnRegular'}
                        onClick={showModal}
                        style={{ color: '#273DF2' }}
                        className="addGroupText"
                      >
                        Add sender email address
                      </MdTypography>
                    </MdGlobalButton>
                  </div>
                </div>
              </div>
            )}
          </Form.Item>
          <div className={`instant-email-form-button ${isFromAndReplyToValidated ? '' : 'button-opacity'}`}>
            <MdGlobalButton
              size={'large'}
              data-test="fromandreply-testbtn"
              htmlType="submit"
              onClick={(data) => {
                handleSaveFromAndReplyTo(data);
                refetch();
              }}
              loading={isEditingMail && editAction === editActions.editFromAndTo}
              disabled={!isFromAndReplyToValidated}
            >
              Save
            </MdGlobalButton>
          </div>
        </div>
        <div className="prototype-container">
          <EmailDisplayPrototype name={fromName} subject={emailSubject} />
        </div>
      </Form>
    </>
  );
};

export default Step2FromandReplyTo;
