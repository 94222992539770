import React, { useEffect, useState } from 'react';
import { Modal, Form, Input } from 'antd';
import OptInHeaderImg from '../../asset/images/LandingHeaderImg.svg';
import { MdOptInHeader } from './MdOptInHeader';
import styles from '../../styles/optInPage.module.scss';
import box from '../../asset/images/null.svg';
import { MdGlobalButton, MdTypography } from 'components/global';
import {
  useGetOptInPageQuery,
  useCreateOptInPageTitleMutation,
} from '../../redux/featureApi/optInPageApiSlice';
import { showToastMessage } from 'utils/Toast';
import { useParams, useNavigate } from 'react-router-dom';
import { errorMessage } from '../../utils/getErrors';
import { deleteOptInPageApi} from 'utils/service';
import LoadingScreen from 'components/LoadingScreen';
import moment from 'moment';
import { OptInPageListItem } from './components/OptInPageListItem';
import useDebounce from 'hooks/useDebounce';
import { mustNotContainSpecialCharacters } from 'utils/formRules';

const MdOptInPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newOptInPage, setNewOptInPage] = useState([]);
  const [oldOptInPage, setOldOptInPage] = useState([]);
  const [loading, setLoading] = useState({ id: '', type: '' });
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 1000) || '';
  const showModal = () => { 
    setIsModalOpen(true);
  };
  const {
    data: getOptInPageData,
    isLoading,
    isFetching,
    isError: getOptInPageError,
  } = useGetOptInPageQuery(debouncedSearchTerm, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
    skip: false,
  });

  const navigate = useNavigate();

  const webUrl = window.location.origin;

  const searchPage = (e) => {
    setSearchTerm(e.target.value);
  };

  const deleteOptInPage = (id) => {

    setOldOptInPage((prevPages) => prevPages.filter((file) => file._id !== id)) ||
    setNewOptInPage((prevPages) => prevPages.filter((file) => file._id !== id));
    
    deleteOptInPageApi(id)
      .then(() => {
        showToastMessage({
          type: 'success',
          title: 'File',
          description: 'File Deleted',
        });
      })
      .catch((e) => {
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: 'An error occurred',
        });
      });
  };


  const CreateNewOptInPage = () => {
    const [optInPageForm] = Form.useForm();
    const { payload } = useParams();
    const [addNewOptInPageTitle, { isLoading: postingNewOptInPage }] = useCreateOptInPageTitleMutation(payload);

    //to check if the optin title Already exists

    const onSubmitOptInPage = async (title) => {
      const optInValue = optInPageForm.getFieldValue().title;
      const optinList = getOptInPageData;
      const optinTitle = optinList?.data?.optInPages?.map((list) => list.title);
      const optinExist = optinTitle?.some((optIns) => optIns?.toLowerCase() === optInValue?.toLowerCase());

      if (optinExist) {
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: 'An opt-in page with that title Already exists...',
        });
        setIsModalOpen(true);
      } else if (!optinExist) {
        try {
          await addNewOptInPageTitle({
            payload: title,
          })
            .unwrap()
            .then((res) => {
              showToastMessage({
                type: 'success',
                title: 'Opt-in Page Title Added',
                description: 'Title Successfully Added',
              });
              const optInPageId = res?.data?.pageId;
              const optInPageName = res?.data?.title;
              setIsModalOpen(false);
              navigate(`/opt-in-pages/drafts/${optInPageName}/${optInPageId}`);
            });
        } catch (error) {
          const errMsg = errorMessage(error);
          setIsModalOpen(false);
          showToastMessage({
            type: 'error',
            title: 'Error',
            description: errMsg,
          });
        }
      }
    };

    return (
      <Modal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
        className="emmaccen create-campaign-modal"
        centered
      >
        <div>
          <MdTypography variant={'h5Medium'} className="text-center">
            New Opt-In Page
          </MdTypography>
          <div className="modalAlert">
            <div>
              <MdTypography variant={'btnLight'} className="modalText">
                This will be created as a new opt-in page. Please enter the title of the opt-in page you would like to
                create and proceed.
              </MdTypography>
            </div>
            <div className="modalImg">
              <div className="illustration">
                <img src={OptInHeaderImg} alt="" />
              </div>
            </div>
          </div>
          <div data-test="campaign-input">
            <Form
              size="large"
              form={optInPageForm}
              onFinish={onSubmitOptInPage}
              onFinishFailed={() => null}
              autoComplete="off"
              layout={'vertical'}
              requiredMark={false}
            >
              <label className="mb-2 formLabel">Opt-in page title</label>
              <Form.Item
                name="title"
                rules={[
                  {
                    required: true,
                    message: 'Please give your opt-in page a name',
                    whitespace: true,
                  },
                  {
                    ...mustNotContainSpecialCharacters[0],
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <div className="centered mt-10">
                <MdGlobalButton
                  id={'primary_lg'}
                  type="primary"
                  htmlType="submit"
                  dataTestId="create-opt-in-page-submit"
                  loading={postingNewOptInPage}
                >
                  <MdTypography variant={'h6Medium'} style={{ color: '#ffffff' }}>
                    Proceed
                  </MdTypography>
                </MdGlobalButton>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    );
  };

  useEffect(() => {
    if (getOptInPageData) {
      const optInPageData = getOptInPageData?.data?.optInPages;
      setNewOptInPage(optInPageData?.filter((x) => moment().diff(moment(x?.createdAt), 'month') < 1));
      setOldOptInPage(optInPageData?.filter((x) => moment().diff(moment(x?.createdAt), 'month') >= 1));
    } else if (getOptInPageError) {
      showToastMessage({
        type: 'error',
        title: 'Error',
        description: getOptInPageError?.data?.message || 'An error occured',
      });
    }
  }, [getOptInPageData, isFetching, getOptInPageError]);

  return (
    <div className="container" data-test="opt-in-page-container">
      <CreateNewOptInPage />
      <MdOptInHeader
        title="Opt-in Pages"
        description="Create quick opt-in pages,collect leads directly to your contact groups"
        OptInHeaderImg={OptInHeaderImg}
        onClick={showModal}
        imLoading={isFetching}
        searchPage={searchPage}
      />
      {isFetching || isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          {' '}
          {getOptInPageData?.data?.optInPages?.length === 0 && debouncedSearchTerm !== '' && !isFetching ? (
            <div className={styles.empty_container}>
              <img src={box} alt="" />
              <p>There are no opt-in pages that matched your search keyword</p>
            </div>
          ) : getOptInPageData?.data?.optInPages?.length === 0 ? (
            <div className={styles.empty_container}>
              <img src={box} alt="" />
              <p>There are no opt-in pages yet</p>
            </div>
          ) : (
            <div data-test="created-opt-in-page" className={styles.container}>
              {newOptInPage?.length > 0 && (
                <div className={styles.new}>
                  <div className={styles.new_status}>
                    <p>New</p>
                  </div>
                  {newOptInPage.map((datas) => {
                    return (
                      <div data-test="opt-in-page-item" key={datas?.id}>
                        <OptInPageListItem
                          datas={datas}
                          deleteOptInPage={deleteOptInPage}
                          loading={loading}
                          styles={styles}
                          setLoading={setLoading}
                          webUrl={webUrl}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
              {oldOptInPage?.length > 0 && (
                <div className={styles.new}>
                  <div className={styles.old_status}>
                    <p>Older than a month</p>
                  </div>
                  {oldOptInPage.map((datas) => {
                    return (
                      <div data-test="opt-in-page-item" key={datas?.id}>
                        <OptInPageListItem
                          datas={datas}
                          deleteOptInPage={deleteOptInPage}
                          loading={loading}
                          styles={styles}
                          setLoading={setLoading}
                          webUrl={webUrl}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default MdOptInPage;
