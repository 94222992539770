import { apiAuthSlice } from './apiAuthSlice';

export const instantEmailApiSlice = apiAuthSlice
  .enhanceEndpoints({ addTagTypes: ['InstantEmailId', 'SpecificInstantEmailTotalSubscribers'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      addInstantEmailId: builder.mutation({
        query: ({ title }) => ({
          url: `/instant-emails`,
          method: 'POST',
          body: { title },
        }),
        invalidatesTags: ['InstantEmailList'],
      }),

      uploadContactsToInstantEmail: builder.mutation({
        query: ({ mailId, contacts }) => ({
          url: `/instant-emails/upload/${mailId}`,
          method: 'POST',
          body: { contacts },
        }),
        invalidatesTags: ['UploadContactsToInstantEmail'],
      }),

      getSaveGroups: builder.mutation({
        query: ({ mailId, groups, recipients }) => ({
          url: `instant-emails/save-groups/${mailId}`,
          method: 'POST',
          body: { groups, recipients },
        }),
        invalidatesTags: ['SaveGroupsToInstantEmail', 'SpecificInstantEmailTotalSubscribers'],
      }),

      getAllInstantEmails: builder.query({
        query: ({ status = '', search = '', page = 0 }) => ({
          url: `/instant-emails?status=${status}&search=${search}&limit=10&page=${page}`,
          method: 'GET',
        }),
        invalidatesTags: ['InstantEmailList', 'SpecificInstantEmail', 'SaveGroupsToInstantEmail'],
        providesTags: (result) =>
          result
            ? [
                ...result?.data?.emails.map(({ _id }) => ({ type: 'InstantEmailList', id: _id })),
                { type: 'InstantEmailList', id: 'LIST' },
              ]
            : [{ type: 'InstantEmailList', id: 'LIST' }],
      }),

      getSingleInstantEmail: builder.query({
        query: ({ mailId }) => ({
          url: `instant-emails/${mailId}`,
          method: 'GET',
        }),
        providesTags: ['SpecificInstantEmail'],
      }),

      deleteInstantEmail: builder.mutation({
        query: ({ mailId }) => ({
          url: `/instant-emails/${mailId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['InstantEmailList'],
      }),

      editInstantEmail: builder.mutation({
        query: ({ mailId, body }) => ({
          url: `instant-emails/${mailId}`,
          method: 'PATCH',
          body: body,
        }),
        invalidatesTags: ['SpecificInstantEmail', 'InstantEmailList', 'SaveGroupsToInstantEmail'],
        transformResponse: (response) => response?.data,
      }),

      cloneInstantEmail: builder.mutation({
        query: ({ mailId }) => ({
          url: `instant-emails/clone/${mailId}`,
          method: 'POST',
          body: { title: 'Instant Email Title' },
        }),
        invalidatesTags: ['InstantEmailList'],
      }),

      saveDraftEmail: builder.mutation({
        query: ({ mailId, body }) => ({
          url: `instant-emails/save-as-draft/${mailId}`,
          method: 'POST',
          body,
        }),
        invalidatesTags: ['SpecificInstantEmail', 'SaveGroupsToInstantEmail'],
        transformResponse: (response) => response?.data,
      }),
      saveUserTemplate: builder.mutation({
        query: ({ mailId, isDefaultTemplate }) => ({
          url: `templates`,
          method: 'POST',
          body: { emailId: mailId, isDefaultTemplate },
        }),
        invalidatesTags: ['SpecificInstantEmail'],
        transformResponse: (response) => response?.data,
      }),
      saveSpeditorTemplate: builder.mutation({
        query: ({ emailId, payload }) => ({
          url: `strippo/${emailId}`,
          method: 'PATCH',
          body: payload,
        }),
        invalidatesTags: ['SpecificInstantEmail'],
        transformResponse: (response) => response?.data,
      }),      
    }),
    overrideExisting: true,
  });

// ======== All actions should be exported in the same order the were defined
export const {
  useAddInstantEmailIdMutation, // represents first action
  useUploadContactsToInstantEmailMutation, // represents second action
  useGetSaveGroupsMutation, // represents third action
  useGetAllInstantEmailsQuery, // represents fourth action
  useGetSingleInstantEmailQuery, // represents fifth action
  useDeleteInstantEmailMutation, // represents sixth action
  useEditInstantEmailMutation, // represents seventh action
  useCloneInstantEmailMutation, // represents eight action
  useSaveDraftEmailMutation, // represents ninth action
  useSaveUserTemplateMutation, // represents tenth action
  useSaveSpeditorTemplateMutation, // represents eleventh action
} = instantEmailApiSlice;
