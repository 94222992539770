import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './AddSenderEmail.module.css';
import icon from './maildripIcon.svg';
import { getAddVerifiedEmail } from 'utils/service';
import { showToastMessage } from 'utils/Toast';
import CircularSpinner from 'components/loaders/CircularSpinner';

const AddSenderEmailPage = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  console.log(error)

  const handleSubmit = async(id) => {
    setLoading(true);
    try {
      const data = await getAddVerifiedEmail(id);

      setLoading(false);
      setError(false);
      
      showToastMessage({
        type: 'success',
        title: 'Success',
        description: data?.message || 'Email successfully added',
      });
  
      setTimeout(() => {
        navigate('/login');
      }, 4000);
      
    }
      catch(errorMsg) {
        const err = errorMsg?.response?.data;
        setError(true);
        setLoading(false);
          showToastMessage({
            type: 'error',
            title: 'Error',
            description: 'Token expired'||err.message,
          });
      }
  };

  useEffect(() => {
    handleSubmit(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <div className={styles.Container}>
        <CircularSpinner className="mx-auto" />
      </div>
    )
  }

  if (error) {
    return (
      <div className={styles.Container}>
        <div className={styles.InnerContainer}>
          <div className={styles.FirstBox}>
            <img src={icon} alt="" />
          </div>
          <p>This token has expired</p>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.Container}>
        <div className={styles.InnerContainer}>
          <div className={styles.FirstBox}>
            <img src={icon} alt="" />
          </div>
          <p>Your Email has been verified.</p>
          <p>You will be redirected to the login page in a minute.</p>
          <div className="mt-10">
            <p className="text-base">
              click{' '}
              <span className="text-blue-500 inline-block" onClick={() => navigate('/login')}>
                here
              </span>{' '}
              if you have not yet been redirected.
            </p>
          </div>
        </div>
    </div>
  );
};

export default AddSenderEmailPage;
