import { LoadingOutlined } from '@ant-design/icons';
import { Collapse, Modal, Form, Input } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as EmptyStateImage } from '../../../asset/images/emptyTableIcon.svg';
import { MdTypography } from '../../../components/global';
import {
  useGetActiveCampaignEmailsQuery,
  useGetDeletedCampaignEmailsQuery,
  useGetDraftedCampaignEmailsQuery,
  useRestoreDeletedMailToDraftMutation,
  useGetSpecificEmailAnalyticsQuery,
  useEditEmailsMutation,
} from '../../../redux/featureApi/emailApiSlice';
import { showToastMessage } from '../../../utils/Toast';
import LoadingScreen from '../../LoadingScreen';

import analyticIcon from '../../../asset/Icons/analyticIcon.png';
import fullViewIcon from '../../../asset/Icons/fullViewIcon.png';
import messageEditIcon from '../../../asset/Icons/messageEdit.png';
import trash from '../../../asset/Icons/trash.png';
import { MdEmailPreview } from './MdEmailPreview';
import { LeftArrowIcon } from 'asset/Icons/LeftArrow';
import { MdGlobalButton } from 'components/global';
import { CAMPAIGN_POLLING_INTERVAL } from 'utils/constants';

export const MdEmails = ({
  data,
  setRefreshTime,
  refreshTime,
  setModalVisible,
  setIsDeleting,
  campaignId,
  isDeleting,
  analyticsModal,
  setAnalyticsModal,

  isDeletingCampaignEmail,
  isSendingEmailToTrash,
  handleEmailInterval,
  editEmail,
  isUpdatingEmailInterval,

  searchEmails,
}) => {
  /* eslint-disable */
  const { Panel } = Collapse;
  const [currentEmailId, setCurrentEmailId] = useState('');
  const navigate = useNavigate();
  const [activeMail, setActiveMails] = useState({ id: 'mail' });
  const [isFilterEmpty, setIsFilterEmpty] = useState(false);
  const [imLoading, setImLoading] = useState({ id: '' });
  const [isFetched, setIsFetched] = useState(true);

  const [mutableData, setMutableData] = useState({ emails: [], mail: [], draft: [], deleted: [] });
  const [originalMutableData, setOriginalMutableData] = useState({ emails: [], mail: [], draft: [], deleted: [] });
  const [initialPreview, setInitialPreview] = useState({});
  const [preview, setPreview] = useState({});
  const [viewAnalytics, setViewAnalytics] = useState({});

  const { data: activeCampaignEmails, isFetching: isActiveEmailFetched } = useGetActiveCampaignEmailsQuery(campaignId, {
    refetchOnMountOrArgChange: true,
  });
  const { data: draftedCampaignEmails, isFetching: isDraftedEmailFetched } = useGetDraftedCampaignEmailsQuery(
    campaignId,
    { refetchOnMountOrArgChange: true }
  );
  const { data: deletedCampaignEmails, isFetching: isDeletedEmailFetched } = useGetDeletedCampaignEmailsQuery(
    campaignId,
    { refetchOnMountOrArgChange: true }
  );

  const [editEmails] = useEditEmailsMutation();

  const reversedDraftedCampaign = Array.isArray(draftedCampaignEmails) ? [...draftedCampaignEmails].reverse() : [];

  const reversedDeletedCampaign = Array.isArray(deletedCampaignEmails) ? [...deletedCampaignEmails].reverse() : [];

  const [restoreMailToDraft, { isLoading: isRestoreDeletedMailToDraft }] = useRestoreDeletedMailToDraftMutation();

  const setNewPreviewContent = (mailId, index) => {
    const newPreview = data.emails.filter(({ _id: id }) => id === mailId);
    setPreview({ ...newPreview[0], index });
  };

  const setupMailData = () => {
    if (!isActiveEmailFetched && !isDraftedEmailFetched && !isDeletedEmailFetched) {
      const mailData = {
        mail: activeCampaignEmails.result,
        draft: reversedDraftedCampaign,
        deleted: reversedDeletedCampaign,
      };
      setMutableData({ ...data, ...mailData });
      setOriginalMutableData({ ...data, ...mailData });

      switch (activeMail.id) {
        case 'draft':
          setInitialPreview(draftedCampaignEmails[0]);
          break;
        case 'deleted':
          setInitialPreview(deletedCampaignEmails[0]);
          break;
        default:
          setInitialPreview(activeCampaignEmails[0]);
          break;
      }
    }
  };
  useEffect(() => {
    setIsFilterEmpty((mutableData[activeMail.id] && !mutableData[activeMail.id].length) || !data.emails.length);
  }, [activeMail, mutableData]);

  useEffect(setupMailData, [
    isActiveEmailFetched,
    isDraftedEmailFetched,
    isDeletedEmailFetched,
    activeCampaignEmails,
    draftedCampaignEmails,
    deletedCampaignEmails,
    activeMail.id,
  ]);

  const restoreMailsToDraft = (payload) => {
    restoreMailToDraft(payload)
      .then(() => {
        showToastMessage({
          type: 'success',
          title: 'Mail Restored',
          description: 'Mail restored successfully',
        });
      })
      .catch(() => {
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: 'Unable to restore mail',
        });
      });
  };

  // learn how to reftch/restore without unmounting
  const restoreMailsToEditing = (payload) => {
    console.log('payE', payload);
  };

  const restoreMail = (mailId, restoreType) => {
    setImLoading({ id: mailId });
    restoreType === 'deleted'
      ? restoreMailsToDraft({ campaignId, mailId })
      : restoreMailsToEditing({ campaignId, mailId });
  };

  const handleAnalyticsView = (emailId) => {
    setAnalyticsModal(true);
    setCurrentEmailId(emailId);
  };

  const Analytics = () => {
    const {
      data: specificEmailAnalytics,
      isFetching: fetchingEmailAnalytics,
      isSuccess: fetchedEmailAnalytics,
    } = useGetSpecificEmailAnalyticsQuery({ emailId: currentEmailId }, { skip: !currentEmailId });

    useEffect(() => {
      if (specificEmailAnalytics) setViewAnalytics(specificEmailAnalytics.data);
    }, [specificEmailAnalytics, fetchedEmailAnalytics]);

    return (
      <Modal
        className="emmaccen mailAnalyticsModal"
        open={analyticsModal}
        onCancel={() => {
          setViewAnalytics({});
          setAnalyticsModal(false);
          setRefreshTime(CAMPAIGN_POLLING_INTERVAL);
        }}
        closable={false}
        footer={null}
        centered
      >
        {fetchingEmailAnalytics ? (
          <LoadingScreen loaderTitle="Loading" />
        ) : (
          <div className="mailAnalyticsContainer">
            <div className="analyHeader">
              <MdTypography variant={'h5Medium'} className="titleHead">
                Reporting
              </MdTypography>
              <div
                className="detailView pointer"
                onClick={() =>
                  navigate(`/emails/${currentEmailId}/report`, {
                    state: { page: 'Campaign' },
                  })
                }
              >
                <MdTypography variant={'smallTextMedium'} className="p-2">
                  Detailed view
                </MdTypography>
                <img src={fullViewIcon} alt="expand icon" />
              </div>
            </div>
            <div className="analytics">
              <div className="grid-3 content mb-5">
                <div className="text-center item">
                  <MdTypography variant={'h4Medium'} style={{ color: '#717171' }}>
                    {viewAnalytics?.uniqueOpens || 0}
                  </MdTypography>
                  <MdTypography variant={'btnLight'} style={{ color: '#555555' }}>
                    Unique Opens
                  </MdTypography>
                </div>
                <div className="text-center item">
                  <MdTypography variant={'h4Medium'} style={{ color: '#717171' }}>
                    {viewAnalytics?.uniqueClicks || 0}
                  </MdTypography>
                  <MdTypography variant={'btnLight'} style={{ color: '#555555' }}>
                    Unique Clicks
                  </MdTypography>
                </div>
                <div className="text-center item">
                  <MdTypography variant={'h4Medium'} style={{ color: '#717171' }}>
                    {viewAnalytics?.deliveryRate || 0}%
                  </MdTypography>
                  <MdTypography variant={'btnLight'} style={{ color: '#555555' }}>
                    Delivery Rate
                  </MdTypography>
                </div>
                <div className="text-center item">
                  <MdTypography variant={'h4Medium'} style={{ color: '#717171' }}>
                    {viewAnalytics?.bounces || 0}
                  </MdTypography>
                  <MdTypography variant={'btnLight'} style={{ color: '#555555' }}>
                    Bounced
                  </MdTypography>
                </div>
                <div className="text-center item">
                  <MdTypography variant={'h4Medium'} style={{ color: '#717171' }}>
                    {viewAnalytics?.unsubscribes || 0}
                  </MdTypography>
                  <MdTypography variant={'btnLight'} style={{ color: '#555555' }}>
                    Unsubscribed
                  </MdTypography>
                </div>
                <div className="text-center item">
                  <MdTypography variant={'h4Medium'} style={{ color: '#717171' }}>
                    {viewAnalytics?.spams || 0}
                  </MdTypography>
                  <MdTypography variant={'btnLight'} style={{ color: '#555555' }}>
                    Spam
                  </MdTypography>
                </div>
              </div>
              {/* <div className="flex grid-3"> */}
              <div className="flex justify-content-between flex-row mt-5 pt-5">
                <div className="flex items-center">
                  <span className="successColorLightBg"></span>
                  <MdTypography variant={'h6Light'} className="pr-2" style={{ color: '#555555' }}>
                    Deliveries
                  </MdTypography>
                  <MdTypography variant={'h6Medium'} style={{ color: '#555555' }}>
                    {viewAnalytics?.delivers || 0}
                  </MdTypography>
                </div>
                <div className="flex items-center">
                  <span className="warningColorBg "></span>
                  <MdTypography variant={'h6Light'} className="pr-2" style={{ color: '#555555' }}>
                    Total Opens
                  </MdTypography>
                  <MdTypography variant={'h6Medium'} style={{ color: '#555555' }}>
                    {viewAnalytics?.opens || 0}
                  </MdTypography>
                </div>
                <div className="flex items-center">
                  <span className="skyColorBg "></span>
                  <MdTypography variant={'h6Light'} className="pr-2" style={{ color: '#555555' }}>
                    Total Clicks
                  </MdTypography>
                  <MdTypography variant={'h6Medium'} style={{ color: '#555555' }}>
                    {viewAnalytics?.clicks || 0}
                  </MdTypography>
                </div>
              </div>
              <div className="flex grid-3">
                <div className="flex items-center mr-5 mt-5">
                  <span className="successColorLightBg "></span>
                  <MdTypography variant={'h6Light'} className="pr-2" style={{ color: '#555555' }}>
                    CTOR
                  </MdTypography>
                  <MdTypography variant={'h6Medium'} style={{ color: '#555555' }}>
                    {viewAnalytics?.ctor || 0}%
                  </MdTypography>
                </div>
                <div className="flex items-center ml-5 mt-5">
                  <span className="dangerColorBg "></span>
                  <MdTypography variant={'h6Light'} className="pr-2" style={{ color: '#555555' }}>
                    CTR
                  </MdTypography>
                  <MdTypography variant={'h6Medium'} style={{ color: '#555555' }}>
                    {viewAnalytics?.ctr || 0}%
                  </MdTypography>
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>
        )}
      </Modal>
    );
  };

  const CollapsePanelHeader = ({ title, subTitle }) => (
    <div className="flex flex-col mt-2 mb-2 collapseHeader">
      <div className="flex items-center mb-2">
        <MdTypography variant={'h5Medium'} className="text-black leading-tight">
          {title}
        </MdTypography>
      </div>
      <MdTypography variant={'smallTextRegular'} className="text-[#717171] text-[12px]">
        {subTitle}
      </MdTypography>
    </div>
  );

  const searchEmailsFn = () => {
    const searchResult = {
      mail: originalMutableData.mail.filter(
        ({ subject, body }) =>
          subject.toLowerCase().includes(searchEmails.toLowerCase()) ||
          body.toLowerCase().includes(searchEmails.toLowerCase())
      ),
      draft: originalMutableData.draft.filter(
        ({ subject, body }) =>
          subject.toLowerCase().includes(searchEmails.toLowerCase()) ||
          body.toLowerCase().includes(searchEmails.toLowerCase())
      ),

      deleted: originalMutableData.deleted.filter(
        ({ subject, body }) =>
          subject.toLowerCase().includes(searchEmails.toLowerCase()) ||
          body.toLowerCase().includes(searchEmails.toLowerCase())
      ),
    };
    return searchResult;
  };

  useEffect(() => {
    if (!searchEmails.trim()) return setMutableData({ ...originalMutableData });

    const searchResult = searchEmailsFn();
    setMutableData({ ...data, ...searchResult });
  }, [searchEmails, activeMail]);

  //changing or updating the subjectline of the html editor
  const [expandMail, setExpandMail] = useState(false);
  const [panelState, setPanelState] = useState(false);
  const [mailInfo, setMailInfo] = useState({
    subject: '',
  });
  const [mailsId, setMailsId] = useState({
    campaignId: '',
    Id: '',
    typeOfMail: '',
  });

  const [loading, setLoading] = useState(false);

  const handleExpand = (id, typeOfMail, subject, index, campaignIds) => {
    setExpandMail(true);
    setMailsId({ ...mailsId, ['campaignId']: campaignIds, ['Id']: id, ['typeOfMail']: typeOfMail });
    setMailInfo({ ...mailInfo, ['subject']: subject });
  };

  useEffect(() => {
    setMailInfo;
  }, [mailInfo]);

  useEffect(() => {
    if (analyticsModal === true) {
      setRefreshTime(null);
    } else {
      setRefreshTime(CAMPAIGN_POLLING_INTERVAL);
    }
  }, [analyticsModal]);

  const handleSubject = async (values, campaignId, emailId) => {
    setLoading(true);
    setMailInfo((prev) => {
      let updatedMailInfo = { ...prev, subject: values.subject };
      editEmails({
        payload: {
          ...mailInfo,
          subject: updatedMailInfo.subject,
        },

        campaignId,
        emailId,
      })
        .unwrap()
        .then((data) => {
          showToastMessage({
            type: 'success',
            title: 'Email',
            description: 'The Subject of your mail has been updated successfully',
          });
          if (data) {
            setLoading(false);
            setMailInfo(updatedMailInfo);
            setPanelState(false);
          }
        });
    });
  };

  return (
    <div>
      <div className="templateContaner margin-t-b">
        <Analytics />
        <div className="grid2">
          <div className="mailList">
            <div className="grid-3 mailFilterContainer">
              <div
                onClick={() => setActiveMails({ id: 'mail' })}
                className={`filterCard mail ${activeMail.id === 'mail' ? 'active' : ''}`}
              >
                <div className="flex justify-space-between align-items-center">
                  <div className="flex align-items-center">
                    <img
                      className="icon"
                      src={`${
                        activeMail.id === 'mail' ? '/assets/mailFilterIconActive.svg' : '/assets/mailFilterIcon.svg'
                      }`}
                      alt="mail icon"
                    ></img>
                    {imLoading.id === 'mail' && <LoadingOutlined />}
                  </div>
                  <div className="flex align-items-center value ml-4">
                    <MdTypography variant={'h6Medium'} style={{ color: '#717171' }}>
                      Active mails
                    </MdTypography>
                    <span>{mutableData.mail.length}</span>
                  </div>
                </div>
              </div>
              <div
                onClick={() => setActiveMails({ id: 'draft' })}
                className={`filterCard draft ${activeMail.id === 'draft' ? 'active' : ''}`}
              >
                <div className="flex justify-space-between align-items-center">
                  <div className="flex align-items-center">
                    <img
                      className="icon"
                      src={`${
                        activeMail.id === 'draft' ? '/assets/draftFilterActiveIcon.svg' : '/assets/draftFilterIcon.svg'
                      }`}
                      alt="mail icon"
                    ></img>
                    {imLoading.id === 'draft' && <LoadingOutlined />}
                  </div>
                  <div className="flex align-items-center value ml-4">
                    <MdTypography variant={'h6Medium'} style={{ color: '#717171' }}>
                      Draft mails
                    </MdTypography>
                    <span>{mutableData.draft.length}</span>
                  </div>
                </div>
              </div>
              <div
                onClick={() => setActiveMails({ id: 'deleted' })}
                className={`filterCard deleted ${activeMail.id === 'deleted' ? 'active' : ''}`}
              >
                <div className="flex justify-space-between align-items-center">
                  <div className="flex align-items-center">
                    <img
                      className="icon"
                      src={`${
                        activeMail.id === 'deleted'
                          ? '/assets/deletedFilterActiveIcon.svg'
                          : '/assets/deletedFilterIcon.svg'
                      }`}
                      alt="mail icon"
                    ></img>
                    {imLoading.id === 'deleted' && <LoadingOutlined />}
                  </div>
                  <div className="flex align-items-center value ml-4">
                    <MdTypography variant={'h6Medium'} style={{ color: '#717171' }}>
                      Deleted mails
                    </MdTypography>
                    <span>{mutableData.deleted.length}</span>
                  </div>
                </div>
              </div>
            </div>

            {expandMail ? (
              <div>
                <div className="flex items-center  gap-3">
                  <button className=" py-2 px-1 custonBt" onClick={() => setExpandMail(false)}>
                    <LeftArrowIcon />
                  </button>

                  <div className="flex items-center" data-test="subject-title">
                    {loading && <LoadingOutlined />}
                    <MdTypography variant={'h4Medium'} className="or text-black">
                      {mailInfo?.subject}
                    </MdTypography>
                  </div>
                </div>

                <div className="my-5 rounded-none border py-1">
                  <Collapse
                    activeKey={panelState ? ['1'] : []}
                    bordered={false}
                    accordion
                    style={{
                      background: 'white',
                      borderRadius: '0px',
                    }}
                  >
                    <Panel
                      key="1"
                      className="p-1"
                      showArrow={false}
                      extra={
                        <button
                          onClick={() => setPanelState(true)}
                          data-test="edit-subject"
                          style={{
                            borderRadius: '16px',
                            border: '1px solid #273DF2',
                            color: '#273DF2',
                            padding: '4px 10px',
                            fontSize: '12px',
                            fontWeight: 500,

                            whiteSpace: 'nowrap',
                          }}
                        >
                          Edit subject
                        </button>
                      }
                      header={
                        <CollapsePanelHeader
                          title="Email Subject"
                          subTitle={
                            panelState
                              ? "What's the subject line for this email?"
                              : "Craft an engaging email subject line to capture your audience's attention"
                          }
                        />
                      }
                    >
                      <div>
                        <Form
                          layout="vertical"
                          style={{
                            maxWidth: 300,
                          }}
                          name="basic"
                          onFinish={(values) => handleSubject(values, mailsId.campaignId, mailsId.Id)}
                          onFinishFailed={() => null}
                          autoComplete="off"
                          size="large"
                        >
                          <Form.Item
                            label={<span style={{ fontWeight: 400, fontSize: '14px' }}>Subject line</span>}
                            name="subject"
                          >
                            <Input
                              data-test="input-subject"
                              placeholder="Enter your subject line"
                              size="large"
                              style={{
                                borderRadius: '0px',
                                padding: '8px 16px',
                              }}
                            />
                          </Form.Item>

                          <div className="mt-10 flex items-center gap-5">
                            <Form.Item>
                              <MdGlobalButton
                                data-test="submit-subject"
                                size={'large'}
                                htmltype="submit"
                                loading={loading}
                                disabled={false}
                                className="bg-primary text-white w-auto whitespace-no-wrap"
                              >
                                Save subject line
                              </MdGlobalButton>
                            </Form.Item>
                            <Form.Item>
                              <MdGlobalButton
                                data-test="subscribers-save-btn"
                                size={'large'}
                                htmltype="button"
                                className="border -primary text-primary subject-line-btn "
                                onClick={(e) => {
                                  e.preventDefault();
                                  setPanelState(false);
                                }}
                              >
                                Cancel
                              </MdGlobalButton>
                            </Form.Item>
                          </div>
                        </Form>
                      </div>
                    </Panel>

                    <Panel
                      key="2"
                      className="p-2"
                      showArrow={false}
                      extra={
                        <button
                          data-test="edit-body"
                          style={{
                            borderRadius: '16px',
                            border: '1px solid #273DF2',
                            color: '#273DF2',
                            padding: '4px 16px',
                            fontSize: '12px',
                            fontWeight: '500',
                          }}
                          onClick={() => {
                            navigate(
                              {
                                pathname: `/campaign/${campaignId}/${mailsId.Id}/${mailsId.typeOfMail}`,
                              },
                              {
                                state: {
                                  mailId: mailInfo.Id,
                                  firstMail: mailInfo.index === 1 ? 'yes' : 'no',
                                },
                              }
                            );
                          }}
                        >
                          Edit
                        </button>
                      }
                      header={
                        <CollapsePanelHeader
                          title="Email Body"
                          subTitle="Create a simple text email or use an email builder"
                        />
                      }
                    ></Panel>
                  </Collapse>
                </div>
              </div>
            ) : (
              <div>
                {isActiveEmailFetched && isDeletedEmailFetched && isDraftedEmailFetched ? (
                  <LoadingScreen loaderTitle="Loading" />
                ) : !isFilterEmpty ? (
                  <div className="scheduleList" data-test="email-list">
                    {mutableData[activeMail?.id || 'emails'].map(({ _id: id, subject, typeOfMail }, index) => (
                      <div key={id} className="flex w-full mb-2" data-test={`email-item-${index + 1}`}>
                        <span className={'mailIndex ' + `${preview._id === id ? 'indexSelected' : ''}`}>{++index}</span>
                        <div className={'card ' + `${preview._id === id ? 'selected' : ''}`}>
                          <>
                            <div onClick={() => setNewPreviewContent(id, index)} className="flex-space-btw mail">
                              <div className="flex items-center align-center">
                                <h3 className="subject">{subject}</h3>
                              </div>
                              <div className="flex justify-center items-center">
                                {activeMail.id === 'mail' ? (
                                  <span title="Analytics" onClick={() => handleAnalyticsView(id)}>
                                    <img
                                      src={analyticIcon}
                                      alt="analytic icon"
                                      className="successColorLight block pr-3 showCampaignAnalyticsButton"
                                    />
                                  </span>
                                ) : (
                                  ''
                                )}
                                {(activeMail.id === 'mail' || activeMail.id === 'draft') && (
                                  <button
                                    data-test="edit-email-btn"
                                    title="Edit"
                                    onClick={() => {
                                      typeOfMail === 'text-editor'
                                        ? navigate(
                                            {
                                              pathname: `/campaign/${campaignId}/${id}/${typeOfMail}`,
                                            },
                                            {
                                              state: {
                                                mailId: id,
                                                firstMail: index === 1 ? 'yes' : 'no',
                                              },
                                            }
                                          )
                                        : typeOfMail === 'speditor' &&
                                          handleExpand(id, typeOfMail, subject, index, campaignId);
                                    }}
                                  >
                                    <img src={messageEditIcon} alt="edit icon" className="block" />
                                  </button>
                                )}
                                {activeMail.id === 'deleted' &&
                                  (imLoading.id === id && isRestoreDeletedMailToDraft ? (
                                    <LoadingOutlined />
                                  ) : (
                                    <MdTypography
                                      variant={'btnRegular'}
                                      style={{ color: '#37b34a' }}
                                      title={
                                        activeMail.id === 'deleted' ? 'Restore Mail to draft' : 'Restore Mail to active'
                                      }
                                      className="pl-2 actionBtn"
                                      onClick={() => {
                                        restoreMail(id, activeMail.id);
                                      }}
                                    >
                                      Restore
                                    </MdTypography>
                                  ))}
                                <div>
                                  {isDeleting.id === id && (isDeletingCampaignEmail || isSendingEmailToTrash) ? (
                                    <LoadingOutlined />
                                  ) : (
                                    <img
                                      src={trash}
                                      alt="trash icon"
                                      data-test="delete-email-btn"
                                      className="pl-3 block img-fluid"
                                      title="Delete"
                                      onClick={() => {
                                        setModalVisible({ id: 'deletingEmailModal' });
                                        setIsDeleting({ id, activeMail: activeMail.id });
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="flex flex-col items-center justify-center mt-5 emptyState">
                    <EmptyStateImage />
                    <p className="mt-3 font-semibold descriptionTextColor font16">
                      Oops! You have no{' '}
                      <span className="capitalize">{activeMail.id === 'mail' ? 'Active' : activeMail.id}</span> Mail
                      available.
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>

          <MdEmailPreview
            preview={!!Object.keys(preview).length ? preview : initialPreview}
            setPreview={setPreview}
            activeMail={activeMail}
            setRefreshTime={setRefreshTime}
            refreshTime={refreshTime}
            campaignId={campaignId}
            data={mutableData[activeMail?.id]}
            handleEmailInterval={handleEmailInterval}
            editEmail={editEmail}
            imLoading={imLoading}
            setModalVisible={setModalVisible}
            isUpdatingEmailInterval={isUpdatingEmailInterval}
          />
        </div>
      </div>
    </div>
  );
};
export default MdEmails;
